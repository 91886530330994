import React, { useState, useEffect } from 'react';
import jsonData from '../Components/TableExcel/cleaned_data.json';
import './ExcelTable.css';
import { getFirestore, getDocs, collection } from 'firebase/firestore';

const ExcelSearchScreen = ({ selectedWorkerId }) => {
    const db = getFirestore();
    const [filteredRows, setFilteredRows] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const restaurantId = localStorage.getItem("restaurantId");
    const uniqueSituations = [...new Set(jsonData.map((row) => row.Situation))];

    useEffect(() => {
        const getAllWorkersByRestaurantId = async (restaurantId) => {
            try {
                const workersSnapshot = await getDocs(collection(db, 'workers'));
                let workersData = workersSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // If a selectedWorkerId is provided, filter workers
                if (selectedWorkerId) {
                    workersData = workersData.filter((worker) => worker.id === selectedWorkerId);
                }

                setWorkers(workersData); // Set the filtered workers data
            } catch (error) {
                console.error('Error fetching worker data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        getAllWorkersByRestaurantId(restaurantId);
    }, [selectedWorkerId]); 

    const filterRowsForWorker = (worker) => {
        return jsonData.filter((row) => {
            const rowNetValue = Math.floor(Number(row.net));
            const inputNetValue = Math.floor(Number(worker.salary));
            const isNetMatch = rowNetValue === inputNetValue;
            const isSituationMatch = row.Situation === worker.statue;
            return isNetMatch && isSituationMatch;
        });
    };

    useEffect(() => {
        if (workers.length > 0) {
            const updatedFilteredRows = workers
                .filter((worker) => !selectedWorkerId || worker.id === selectedWorkerId  || worker.restaurantId == restaurantId)
                .map((worker) => ({
                    worker,
                    rows: filterRowsForWorker(worker),
                }));
            setFilteredRows(updatedFilteredRows);
        }
    }, [workers, selectedWorkerId]);

    const formatNumber = (value) => parseFloat(value).toFixed(3);

    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div  style={{ marginBottom: '50px' }}>
                    {filteredRows.length > 0 ? (
                        <table className="excel-table">
                            <thead>
                                <tr>
                                    <th>Nom Salarié</th>
                                    <th>Situation</th>
                                    <th>Salaire Brut</th>
                                    <th>CNSS</th>
                                    <th>Salaire Imposable</th>
                                    <th>IRPP</th>
                                    <th>CSS</th>
                                    <th>Net</th>
                                    <th style={{ backgroundColor: "white", border: "none" }}></th>
                                    <th>TFP</th>
                                    <th>FOPROLOS</th>
                                    <th>Sécurité Sociale</th>
                                    <th>Accident de Travail</th>
                                    <th>Cout Total</th>
                                </tr>
                            </thead>
                            <tbody>
                               {filteredRows
  .filter(workerData => workerData.worker.restaurantId === restaurantId)
  .map((workerData, index) => (
    <React.Fragment key={index}>
      {workerData.rows.length > 0 ? (
        workerData.rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td>{workerData.worker.name}</td>
            <td>{row.Situation}</td>
            <td>{formatNumber(row['Salaire brut'])}</td>
            <td>{formatNumber(row.cnss)}</td>
            <td>{formatNumber(row['Salaire Imposable'])}</td>
            <td>{formatNumber(row.IRPP)}</td>
            <td>{formatNumber(row.CSS)}</td>
            <td>{formatNumber(row.net)}</td>
            <td style={{ backgroundColor: "white", border: "none" }}></td>
            <td>{formatNumber(row['Salaire brut'] * 0.02)}</td>
            <td>{formatNumber(row['Salaire brut'] * 0.01)}</td>
            <td>{formatNumber(row['Salaire brut'] * 0.1657)}</td>
            <td>{formatNumber(row['Salaire brut'] * 0.005)}</td>
            <td>{formatNumber(row['Salaire brut'] * 0.1657 + row['Salaire brut'] * 0.01 + row['Salaire brut'] * 0.02 + row['Salaire brut'] * 0.005 + row['Salaire brut'])}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="8">No results found for this worker's criteria</td>
        </tr>
      )}
    </React.Fragment>
  ))}

                                   
                            </tbody>
                        </table>
                    ) : (
                        <p>No workers found.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default ExcelSearchScreen;
