import { getFirestore, doc, setDoc, getDoc, updateDoc, deleteDoc, collection, getDocs, onSnapshot } from "firebase/firestore";

const db = getFirestore();

class Fournisseur {
  constructor(name, numero, listDeProduit, image, restaurantId = null) {
    this.name = name;
    this.numero = numero;
    this.listDeProduit = listDeProduit;
    this.image = image;
    this.restaurantId = restaurantId;
    this.id = null; // Assigned after adding the fournisseur
  }

  // Add a new fournisseur
  async addFournisseur() {
    try {
      const fournisseurRef = doc(collection(db, "fournisseurs"));
      await setDoc(fournisseurRef, {
        name: this.name,
        numero: this.numero,
        listDeProduit: this.listDeProduit,
        image: this.image,
        restaurantId: this.restaurantId, // Include restaurantId when adding
      });
      this.id = fournisseurRef.id; // Assign the generated ID
    } catch (error) {
      console.error("Error adding fournisseur:", error.message);
      throw new Error(error.message);
    }
  }

  // Update fournisseur
  static async updateFournisseur(fournisseurId, updates) {
    try {
      const fournisseurRef = doc(db, "fournisseurs", fournisseurId);
      await updateDoc(fournisseurRef, updates);
    } catch (error) {
      console.error("Error updating fournisseur:", error.message);
      throw new Error(error.message);
    }
  }

  // Get all fournisseurs (one-time fetch), optionally filtered by restaurantId
  static async getAllFournisseurs(restaurantId = null) {
    try {
      const fournisseursCollection = collection(db, "fournisseurs");
      const snapshot = await getDocs(fournisseursCollection);
      const fournisseurs = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((fournisseur) => !restaurantId || fournisseur.restaurantId === restaurantId); // Filter by restaurantId if provided
      return fournisseurs;
    } catch (error) {
      console.error("Error retrieving fournisseurs:", error.message);
      throw new Error(error.message);
    }
  }

  // Real-time updates for fournisseurs, optionally filtered by restaurantId
  static listenFournisseurs(callback, restaurantId = null) {
    try {
      const fournisseursCollection = collection(db, "fournisseurs");
      return onSnapshot(fournisseursCollection, (snapshot) => {
        const fournisseurs = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((fournisseur) => !restaurantId || fournisseur.restaurantId === restaurantId); // Filter by restaurantId if provided
        callback(fournisseurs);
      });
    } catch (error) {
      console.error("Error listening to fournisseurs:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete fournisseur
  static async deleteFournisseur(fournisseurId) {
    try {
      const fournisseurRef = doc(db, "fournisseurs", fournisseurId);
      await deleteDoc(fournisseurRef);
    } catch (error) {
      console.error("Error deleting fournisseur:", error.message);
      throw new Error(error.message);
    }
  }

  // Get fournisseur by ID
  static async getFournisseurById(id) {
    try {
      const fournisseurRef = doc(db, "fournisseurs", id);
      const docSnap = await getDoc(fournisseurRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        throw new Error("Fournisseur not found");
      }
    } catch (error) {
      console.error("Error fetching fournisseur by ID:", error.message);
      throw new Error(error.message);
    }
  }
}

export default Fournisseur;
