import { getFirestore, doc, setDoc, updateDoc, deleteDoc, collection, getDocs, getDoc, query, where } from "firebase/firestore";

const db = getFirestore();

class Decaissement {
  constructor(value, type, date, restaurantId) {
    this.value = value;
    this.type = type;
    this.date = date;
    this.restaurantId = restaurantId; // Added restaurantId
    this.id = null; // Will be assigned after adding to the database
  }

  // Add a new décaissement
  async addDecaissement() {
    try {
      const decaissementRef = doc(collection(db, "decaissements"));
      await setDoc(decaissementRef, {
        value: this.value,
        type: this.type,
        date: this.date,
        restaurantId: this.restaurantId, // Save restaurantId
      });
      this.id = decaissementRef.id;
      console.log("Décaissement added successfully!");
    } catch (error) {
      console.error("Error adding décaissement:", error.message);
      throw new Error(error.message);
    }
  }

  // Update an existing décaissement
  static async updateDecaissement(decaissementId, updates) {
    try {
      const decaissementRef = doc(db, "decaissements", decaissementId);
      const decaissementSnap = await getDoc(decaissementRef);

      if (!decaissementSnap.exists()) {
        throw new Error("Décaissement not found.");
      }

      await updateDoc(decaissementRef, updates);
      console.log("Décaissement updated successfully!");
    } catch (error) {
      console.error("Error updating décaissement:", error.message);
      throw new Error(error.message);
    }
  }

  // Get all décaissements
  static async getAllDecaissements() {
    try {
      const decaissementsSnapshot = await getDocs(collection(db, "decaissements"));
      const decaissements = [];
      decaissementsSnapshot.forEach((doc) => {
        decaissements.push({ id: doc.id, ...doc.data() });
      });
      return decaissements;
    } catch (error) {
      console.error("Error retrieving décaissements:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete a décaissement
  static async deleteDecaissement(decaissementId) {
    try {
      const decaissementRef = doc(db, "decaissements", decaissementId);
      await deleteDoc(decaissementRef);
      console.log("Décaissement deleted successfully!");
    } catch (error) {
      console.error("Error deleting décaissement:", error.message);
      throw new Error(error.message);
    }
  }

  // Get décaissements for a specific restaurantId
  static async getDecaissementsByRestaurantId(restaurantId) {
    try {
      const q = query(collection(db, "decaissements"), where("restaurantId", "==", restaurantId));
      const decaissementsSnapshot = await getDocs(q);
      const decaissements = [];
      decaissementsSnapshot.forEach((doc) => {
        decaissements.push({ id: doc.id, ...doc.data() });
      });
      return decaissements;
    } catch (error) {
      console.error("Error retrieving décaissements by restaurantId:", error.message);
      throw new Error(error.message);
    }
  }


}

export default Decaissement;
