import React from 'react';
import './ExcelTable.css';

const ExcelTableFournisseur = ({ fournisseurs, factures }) => {

  // You may need to map or merge the 'fournisseurs' and 'factures' data depending on how they are related.
  const data = factures.map((facture, index) => ({
    ...facture,
    fournisseur: fournisseurs.find(f => f.id === facture.fournisseurId)?.name || 'Unknown',
  }));
   // Format the date for better readability
   const formatDate = (date) => {
    if (!date) return "Date Inconnue";
    const parsedDate = new Date(date.seconds * 1000); // Assuming date is a Firestore Timestamp
    return parsedDate.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  };
    // Determine the class based on the "statut"
    const getStatutStyle = (statut) => {
      switch (statut) {
        case 'Payée':
          return { color: 'green' };  // Green
        case 'Non Payée':
          return { color: 'red' };    // Red
        case 'En Attente':
          return { color: 'yellow' }; // Yellow
        default:
          return {};
      }
    };
  

  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
            <th colSpan="8" className="header-cell">Facture</th>
          </tr>
          <tr>
            <th className="sub-header-cell">FOURNISSEUR</th>
            
            <th className="sub-header-cell">Achat HTVA</th>
            <th className="sub-header-cell">TVA Déductible</th>
            <th className="sub-header-cell">Achat TTC</th>
            <th className="sub-header-cell">Montant Payé</th>
            <th className="sub-header-cell">Statut</th>
            <th className="sub-header-cell">Reste A Payer</th>
            <th className="sub-header-cell">Mode de paiement</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="data-row">
              <td>{row.date}</td>
            
              <td>{row.fournisseur}</td>
              <td>{row.achatHTVA}</td>
              <td>{row.tvaDeductible}</td>
              <td>{row.achatTTC}</td>
              <td>{row.montantPaye}</td>
              <td style={{  ...getStatutStyle(row.statut) }}>{row.statut}</td>
              <td>{row.resteAPayer}</td>
              <td>{row.modeDePaiement}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default ExcelTableFournisseur;
