import React, { useState, useEffect } from "react";
import RH from "../Services/RH";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ExcelSearchScreen from "./RHEXCEL"; // Import ExcelSearchScreen
import { useNavigate } from "react-router-dom";

const RHPage = () => {
  const restaurantId = localStorage.getItem("restaurantId");
  const [workers, setWorkers] = useState([]);
  const navigate = useNavigate();
  const [newWorker, setNewWorker] = useState({
    name: "",
    prenom: "",
    num: "",
    cin: "",
    numcnss: "",
    role: "",
    statue: "",
    salary: "",
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchWorkers = async () => {
      try {
        const fetchedWorkers = await RH.getAllWorkersByRestaurantId(restaurantId);
        setWorkers(fetchedWorkers);
      } catch (error) {
        console.error("Error fetching workers:", error.message);
      }
    };
    fetchWorkers();
  }, [restaurantId]);

  const handleAddWorker = async () => {
    if (
      !newWorker.name ||
      !newWorker.prenom ||
      !newWorker.num ||
      !newWorker.cin ||
      !newWorker.statue ||
      !newWorker.salary
    )
      return;

    try {
      const addedWorker = await RH.addWorker({ ...newWorker, restaurantId });
      setWorkers([...workers, addedWorker]);
      setNewWorker({
        name: "",
        prenom: "",
        num: "",
        cin: "",
        numcnss: "",
        role: "",
        statue: "",
        salary: "",
      });
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error adding worker:", error.message);
    }
  };

  const handleDeleteWorker = async (workerId) => {
    try {
      await RH.deleteWorker(workerId, restaurantId);
      setWorkers(workers.filter((worker) => worker.id !== workerId));
    } catch (error) {
      console.error("Error deleting worker:", error.message);
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h3"
        gutterBottom
        align="center"
        sx={{ color: "primary.main", fontWeight: "bold", mt: 3 }}
      >
        RH (Gestion des ressources)
      </Typography>

      <ExcelSearchScreen  />

      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
        Gestion des Travailleurs
      </Typography>

      <Grid container spacing={4}>
        {workers.map((worker) => (
          <Grid item xs={12} sm={6} md={4} key={worker.id}>
            <Card
              sx={{
                boxShadow: 5,
                borderRadius: 3,
                ":hover": { transform: "scale(1.03)", transition: "0.3s" },
                border: "1px solid #ddd",
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "secondary.main" }}>
                  {worker.name} - {worker.role}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/worker/${worker.id}`)}
                    color="primary"
                    size="small"
                    sx={{ flex: 0.45 }}
                  >
                    Détail
                  </Button>
                
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteWorker(worker.id)}
                    size="small"
                    sx={{ flex: 0.45 }}
                  >
                    Supprimer
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 5, display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsDialogOpen(true)}
          sx={{ fontWeight: "bold", py: 1.5, px: 4 }}
        >
          Ajouter un Travailleur
        </Button>
      </Box>

      {/* Add Worker Dialog */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Add New Worker</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={newWorker.name}
                onChange={(e) => setNewWorker({ ...newWorker, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Prenom"
                variant="outlined"
                fullWidth
                value={newWorker.prenom}
                onChange={(e) => setNewWorker({ ...newWorker, prenom: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Num"
                variant="outlined"
                type="number"
                fullWidth
                value={newWorker.num}
                onChange={(e) => setNewWorker({ ...newWorker, num: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
  <TextField
    label="CIN"
    variant="outlined"
    type="number"
    fullWidth
    value={newWorker.cin}
    onChange={(e) => {
      const value = e.target.value;
      // Ensure the value is numeric and its length is at most 8 digits
      if (/^\d{0,8}$/.test(value)) {
        setNewWorker({ ...newWorker, cin: value });
      }
    }}
  />
</Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Num CNSS"
                variant="outlined"
                type="number"
                fullWidth
                value={newWorker.numcnss}
                onChange={(e) => setNewWorker({ ...newWorker, numcnss: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>Role</InputLabel>
    <Select
      value={newWorker.role}
      onChange={(e) => setNewWorker({ ...newWorker, role: e.target.value })}
      label="Role"
    >
      
      <MenuItem value="Gérant">Gérant</MenuItem>
      <MenuItem value="Chef de cuisine">Chef de cuisine</MenuItem>
      <MenuItem value="Cuisinier">Cuisinier</MenuItem>
      <MenuItem value="Aide-cuisinier">Aide-cuisinier</MenuItem>
      <MenuItem value="Serveur">Serveur/Serveuse</MenuItem>
      <MenuItem value="Barman">Barman</MenuItem>
      <MenuItem value="Barmaid">Barmaid</MenuItem>
      <MenuItem value="Plongeur">Plongeur</MenuItem>
      <MenuItem value="Hôte">Hôte</MenuItem>
      <MenuItem value="Hôtesse d'accueil">Hôtesse d'accueil</MenuItem>
      <MenuItem value="Responsable de salle">Responsable de salle</MenuItem>
    </Select>
  </FormControl>
</Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Statue</InputLabel>
                <Select
                  label="Statue"
                  value={newWorker.statue}
                  onChange={(e) => setNewWorker({ ...newWorker, statue: e.target.value })}
                >
                  {["Celibataire", "Marie", "M+1", "M+2", "M+3", "M+4"].map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Salary"
                variant="outlined"
                fullWidth
                type="number"
                value={newWorker.salary}
                onChange={(e) => setNewWorker({ ...newWorker, salary: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            Annuler
          </Button>
          <Button
            onClick={handleAddWorker}
            color="primary"
            variant="contained"
            sx={{ fontWeight: "bold" }}
          >
            Ajouter un travailleur
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RHPage;
