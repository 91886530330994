import React, { useState, useEffect } from "react";
import Product from "../Services/FoodCost";
import Ingredient from "../Services/Ingredient";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ExcelTableFoodCost from "../Components/TableExcel/ExcelFoodCost";
import { useNavigate } from "react-router-dom";

const FoodCostPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const restaurantId = localStorage.getItem("restaurantId");
  const [newProduct, setNewProduct] = useState({
    name: "",
    soldPrice: "",
    image: null,
    ingredients: [], // Store selected ingredients and their quantities
  });
  const [editingProduct, setEditingProduct] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedProducts = await Product.getAllProducts(restaurantId);
        const fetchedIngredients = await Ingredient.getAllIngredientsByRestaurant(restaurantId);
        setProducts(fetchedProducts);
        setIngredients(fetchedIngredients);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, []);

  const calculateProductCost = (ingredients, productIngredients) => {
    return productIngredients.reduce((totalCost, productIngredient) => {
      const ingredient = ingredients.find(
        (ing) => ing.id === productIngredient.ingredientId
      );
      return ingredient
        ? totalCost + ingredient.price * productIngredient.quantity
        : totalCost;
    }, 0);
  };

  const handleAddProduct = async () => {
    try {
      const productCost = calculateProductCost(
        ingredients,
        newProduct.ingredients
      );
      const product = new Product(
        newProduct.name,
        parseFloat(newProduct.soldPrice),
        restaurantId,
        newProduct.image,
        newProduct.ingredients
      );
      await product.addProduct();
      setProducts([...products, product]);
      setNewProduct({ name: "", soldPrice: "", image: null, ingredients: [] });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error adding product:", error.message);
    }
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setNewProduct({
      name: product.name,
      soldPrice: product.soldPrice,
      image: product.image,
      ingredients: product.ingredients,
    });
    setOpenDialog(true);
  };

  const handleSaveProduct = async () => {
    try {
      const productCost = calculateProductCost(ingredients, newProduct.ingredients);
  
      const updatedProduct = new Product(
        newProduct.name,
        parseFloat(newProduct.soldPrice),
        restaurantId,
        newProduct.image,
        newProduct.ingredients
      );
  
      await Product.updateProduct(editingProduct.id, updatedProduct);
  
      setProducts(
        products.map((product) =>
          product.id === editingProduct.id
            ? { ...product, ...updatedProduct, cost: productCost }
            : product
        )
      );
  
      setEditingProduct(null);
      setNewProduct({ name: "", soldPrice: "", image: null, ingredients: [] });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving product:", error.message);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await Product.deleteProduct(id);
      setProducts(products.filter((product) => product.id !== id));
    } catch (error) {
      console.error("Error deleting product:", error.message);
    }
  };

  const handleIngredientQuantityChange = (ingredientId, quantity) => {
    setNewProduct((prevProduct) => {
      const updatedIngredients = prevProduct.ingredients.map((ingredient) =>
        ingredient.ingredientId === ingredientId
          ? { ...ingredient, quantity }
          : ingredient
      );
      return { ...prevProduct, ingredients: updatedIngredients };
    });
  };

  const handleAddIngredients = () => {
    const updatedIngredients = newProduct.ingredients.map((ingredient) => ({
      ingredientId: ingredient.ingredientId,
      quantity: ingredient.quantity,
    }));
    setNewProduct({
      ...newProduct,
      ingredients: [...newProduct.ingredients, ...updatedIngredients],
    });
  };

  const handleSelectIngredients = (event) => {
    const selectedIngredients = event.target.value;
    const updatedIngredients = selectedIngredients.map((ingredientId) => ({
      ingredientId,
      quantity: "", // Initialize quantity for each selected ingredient
    }));
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      ingredients: updatedIngredients,
    }));
  };

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Food Cost Calculator
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate("/ingredients")}
        style={{
          marginBottom: "20px",
          backgroundColor: "#4CAF50", // Green
          color: "white",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
      >
        Ingredients
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDialog(true)}
        style={{
          marginBottom: "20px",
          marginLeft: "20px",
          backgroundColor: "#3f51b5", // Blue
          color: "white",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
      >
        Add Product
      </Button>

      <ExcelTableFoodCost data={products} ingredients={ingredients} />

      <br />
      <Grid container spacing={2} justifyContent="center">
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={3} key={product.id}>
            <Card variant="outlined" sx={{ borderRadius: 3, boxShadow: 4, overflow: "hidden" }}>
              <CardMedia
                component="img"
                height="180"
                image={product.image || "https://via.placeholder.com/150"}
                alt={product.name}
                sx={{ objectFit: "cover", borderRadius: 2 }}
              />
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: "bold", color: "#333" }}>
                  {product.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: 14 }}>
                  Price: <strong>{product.soldPrice} TND</strong>
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: 14, mt: 1 }}>
                  Ingredients:
                </Typography>
                <ul style={{ paddingLeft: 20 }}>
                  {product.ingredients.map((ingredientDetail) => {
                    const ingredient = ingredients.find(
                      (ing) => ing.id === ingredientDetail.ingredientId
                    );
                    return (
                      <li key={ingredientDetail.ingredientId} style={{ fontSize: 12 }}>
                        {ingredient
                          ? `${ingredient.name} - ${ingredientDetail.quantity}`
                          : "Unknown Ingredient"}
                      </li>
                    );
                  })}
                </ul>
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: 14 }}>
                  Total Cost: <strong>{product.cost} TND</strong>
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <IconButton color="primary" onClick={() => handleEditProduct(product)} sx={{ padding: 1 }}>
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton>
                <IconButton color="secondary" onClick={() => handleDeleteProduct(product.id)} sx={{ padding: 1 }}>
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} sx={{ borderRadius: 2 }}>
        <DialogTitle>{editingProduct ? "Edit Product" : "Add Product"}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Product Name"
            value={newProduct.name}
            onChange={(e) =>
              setNewProduct({ ...newProduct, name: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="Sold Price"
            type="number"
            value={newProduct.soldPrice}
            onChange={(e) =>
              setNewProduct({ ...newProduct, soldPrice: e.target.value })
            }
            margin="dense"
          />
          <Button
            variant="outlined"
            component="label"
            style={{ marginTop: "10px" }}
          >
            Upload Image
            <input
              type="file"
              hidden
              onChange={(e) =>
                setNewProduct({ ...newProduct, image: e.target.files[0] })
              }
            />
          </Button>

          {/* Multi-select Ingredients */}
          <FormControl fullWidth margin="dense">
            <InputLabel>Ingredients</InputLabel>
            <Select
              multiple
              value={newProduct.ingredients.map((ing) => ing.ingredientId)}
              onChange={handleSelectIngredients}
              renderValue={(selected) =>
                selected.map(
                  (ingredientId) =>
                    ingredients.find((ing) => ing.id === ingredientId)?.name
                ).join(", ")
              }
            >
              {ingredients.map((ingredient) => (
                <MenuItem key={ingredient.id} value={ingredient.id}>
                  <Checkbox
                    checked={newProduct.ingredients.some(
                      (ing) => ing.ingredientId === ingredient.id
                    )}
                  />
                  <ListItemText primary={ingredient.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Render Quantity Inputs for each selected ingredient */}
          {newProduct.ingredients.map((ingredient, index) => (
            <TextField
              key={ingredient.ingredientId}
              fullWidth
              label={`Quantity for ${ingredients.find(
                (ing) => ing.id === ingredient.ingredientId
              )?.name}`}
              type="number"
              value={ingredient.quantity || ""}
              onChange={(e) =>
                handleIngredientQuantityChange(
                  ingredient.ingredientId,
                  e.target.value
                )
              }
              margin="dense"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={editingProduct ? handleSaveProduct : handleAddProduct}
            color="primary"
          >
            {editingProduct ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FoodCostPage;
