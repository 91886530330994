import { getFirestore, doc, setDoc, deleteDoc, collection, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { query, where } from "firebase/firestore";
const db = getFirestore();

class RH {
  constructor(workerId, restaurantId, name, prenom, num, cin, numcnss, role, statue, workingHours = [], salary = 0, accompte = []) {
    this.workerId = workerId;
    this.restaurantId = restaurantId; // New field
    this.name = name;
    this.prenom = prenom;
    this.num = num;
    this.cin = cin;
    this.numcnss = numcnss;
    this.role = role;
    this.statue = statue;
    this.workingHours = workingHours;
    this.salary = salary;
    this.accompte = accompte; // Array of { amount, date }
  }

  // Add or update worker's information
  async addOrUpdateWorker() {
    try {
      const workerRef = doc(db, "workers", this.workerId);
      await setDoc(workerRef, {
        restaurantId: this.restaurantId, // Include restaurantId
        name: this.name,
        prenom: this.prenom,
        num: this.num,
        cin: this.cin,
        numcnss: this.numcnss,
        role: this.role,
        statue: this.statue,
        workingHours: this.workingHours,
        salary: this.salary,
        accompte: this.accompte,
      });
      console.log("Worker added/updated successfully!");
    } catch (error) {
      console.error("Error adding/updating worker:", error.message);
      throw new Error(error.message);
    }
  }

  // Add a new worker
  static async addWorker({ restaurantId, name, prenom, num, cin, numcnss, role, statue, salary }) {
    try {
      const workerRef = doc(collection(db, "workers"));
      const workerData = {
        restaurantId, // Include restaurantId
        name,
        prenom,
        num,
        cin,
        numcnss,
        role,
        statue,
        salary: parseFloat(salary),
        workingHours: [],
        accompte: [],
      };
      await setDoc(workerRef, workerData);
      console.log("Worker added successfully!");
      return { id: workerRef.id, ...workerData };
    } catch (error) {
      console.error("Error adding worker:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete a specific worker
  static async deleteWorker(workerId) {
    try {
      const workerRef = doc(db, "workers", workerId);
      await deleteDoc(workerRef);
      console.log("Worker deleted successfully!");
    } catch (error) {
      console.error("Error deleting worker:", error.message);
      throw new Error(error.message);
    }
  }
  static async getAllWorkersByRestaurantId(restaurantId) {
    try {
      const workersRef = collection(db, "workers");
      const q = query(workersRef, where("restaurantId", "==", restaurantId));
      const workersSnapshot = await getDocs(q);
      const workers = [];
      workersSnapshot.forEach((doc) => {
        workers.push({ id: doc.id, ...doc.data() });
      });
      return workers;
    } catch (error) {
      console.error("Error retrieving workers by restaurantId:", error.message);
      throw new Error(error.message);
    }
  }
  // Get all workers
  static async getAllWorkers() {
    try {
      const workersSnapshot = await getDocs(collection(db, "workers"));
      const workers = [];
      workersSnapshot.forEach((doc) => {
        workers.push({ id: doc.id, ...doc.data() });
      });
      return workers;
    } catch (error) {
      console.error("Error retrieving workers:", error.message);
      throw new Error(error.message);
    }
  }

  // Get a specific worker's information
  static async getWorker(workerId) {
    try {
      const workerRef = doc(db, "workers", workerId);
      const workerSnap = await getDoc(workerRef);

      if (!workerSnap.exists()) {
        throw new Error("Worker not found.");
      }

      return { id: workerId, ...workerSnap.data() };
    } catch (error) {
      console.error("Error retrieving worker:", error.message);
      throw new Error(error.message);
    }
  }

  // Add working hours for a specific worker
  static async addWorkingHours(workerId, hoursWorked, date) {
    try {
      const workerRef = doc(db, "workers", workerId);
      const workerSnap = await getDoc(workerRef);

      if (!workerSnap.exists()) {
        throw new Error("Worker not found.");
      }

      const workerData = workerSnap.data();
      const updatedWorkingHours = [...workerData.workingHours, { hoursWorked, date }];
      const hourlyRate = 15;
      const newSalary = updatedWorkingHours.reduce((total, entry) => total + entry.hoursWorked * hourlyRate, 0);

      await updateDoc(workerRef, {
        workingHours: updatedWorkingHours,
        salary: newSalary,
      });

      console.log("Working hours added successfully!");
    } catch (error) {
      console.error("Error adding working hours:", error.message);
      throw new Error(error.message);
    }
  }

  // Add or update accompte (advance payment) for a specific worker
  static async addAccompte(workerId, amount, date) {
    try {
      if (isNaN(amount) || amount <= 0) {
        throw new Error("Invalid amount.");
      }

      const formattedDate = date instanceof Date ? date : new Date(date);

      const workerRef = doc(db, "workers", workerId);
      const workerSnap = await getDoc(workerRef);

      if (!workerSnap.exists()) {
        throw new Error("Worker not found.");
      }

      const workerData = workerSnap.data();
      const updatedAccompte = [...workerData.accompte, { amount, date: formattedDate }];

      await updateDoc(workerRef, {
        accompte: updatedAccompte,
      });

      console.log("Accompte added successfully!");
    } catch (error) {
      console.error("Error adding accompte:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete accompte (advance payment) for a specific worker
  static async deleteAccompte(workerId, accompteId) {
    try {
      const workerRef = doc(db, "workers", workerId);
      const workerSnap = await getDoc(workerRef);

      if (!workerSnap.exists()) {
        throw new Error("Worker not found.");
      }

      const workerData = workerSnap.data();
      const updatedAccompte = workerData.accompte.filter(
        (accompte) => accompte.id !== accompteId
      );

      if (updatedAccompte.length === workerData.accompte.length) {
        throw new Error("Accompte not found.");
      }

      await updateDoc(workerRef, {
        accompte: updatedAccompte,
      });

      console.log("Accompte deleted successfully!");
    } catch (error) {
      console.error("Error deleting accompte:", error.message);
      throw new Error(error.message);
    }
  }

  // Edit specific fields of a worker
  static async editWorker(workerId, updatedFields) {
    try {
      const workerRef = doc(db, "workers", workerId);
      const workerSnap = await getDoc(workerRef);

      if (!workerSnap.exists()) {
        throw new Error("Worker not found.");
      }

      // Update only the specified fields
      await updateDoc(workerRef, updatedFields);
      console.log("Worker updated successfully!");
    } catch (error) {
      console.error("Error updating worker:", error.message);
      throw new Error(error.message);
    }
  }
}

export default RH;
