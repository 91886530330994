import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from "../../Firebase/Config";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  TextField,
  Divider,
  useMediaQuery,
} from '@mui/material';
import {
  FaHome,
  FaChartPie,
  FaUtensils,
  FaLock,
  FaUsers,
  FaBriefcase,
  FaHandshake,
  FaCog,
  FaSignOutAlt,
  FaSearch,
  FaQuestionCircle,
  FaBars,
} from 'react-icons/fa';
import { useSwipeable } from 'react-swipeable';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const [isOpen, setIsOpen] = useState(true);

  const isMobile = useMediaQuery('(max-width:768px)');
  const user = JSON.parse(localStorage.getItem("user"));

  const handleMenuItemClick = (item) => {
    setActiveItem(item);
    if (isMobile) setIsOpen(false);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    } catch (error) {
      console.error("Logout failed: ", error.message);
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setIsOpen(true),
    onSwipedLeft: () => setIsOpen(false),
    delta: 50,
  });

  const menuItems = [
    { to: '/Home', icon: <FaHome />, label: 'Home' },
    { to: '/Analytics', icon: <FaChartPie />, label: 'Analytique' },
    { to: '/vault', icon: <FaLock />, label: 'Vault' },
    { to: '/rh', icon: <FaUsers />, label: 'RH' },
    { to: '/foodcost', icon: <FaUtensils />, label: 'Food Cost' },
    { to: '/fournisseurs', icon: <FaBriefcase />, label: 'Fournisseurs' },
    { to: '/Facture', icon: <FaHandshake />, label: 'Facture & Livraison' },
    { to: '/partenaire', icon: <FaHandshake />, label: 'Partenaires' },
  ];

  const settingsItems = [
    { to: '/settings', icon: <FaCog />, label: 'Settings' },
    { to: '/help-center', icon: <FaQuestionCircle />, label: 'Help Center' },
    { to: '/', icon: <FaSignOutAlt />, label: 'Logout', action: handleLogout },
  ];

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        width: isMobile ? '100%' : 280,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : 280,
          background: 'linear-gradient(135deg, #000863, #0056b3)',
          color: '#ffffff',
          transition: 'width 0.3s ease-in-out',
          borderTopRightRadius: '55px',
        },
      }}
      {...swipeHandlers}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {/* Logo */}
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/LogoOptimizi.png`}
            alt="Logo"
            style={{ width: '70px', height: '70px', filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))' }}
          />
        </div>

        {/* Profile Section */}
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/ProfilePic.jpg`}
            alt="Profile"
            style={{
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              border: '3px solid #ffffff',
              cursor: 'pointer',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            }}
            onClick={handleProfileClick}
          />
          <div style={{ marginTop: '10px' }}>
            <h3 style={{ margin: 0, fontWeight: 600 }}>{user?.prenom} {user?.nom}</h3>
            <p style={{ margin: 0, fontSize: '0.9rem', color: '#b0c4de' }}>
              {user?.role || 'Loading...'}
            </p>
          </div>
        </div>

        {/* Search Section */}
        <div style={{ padding: '10px' }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search here..."
            InputProps={{
              startAdornment: <FaSearch style={{ marginRight: '10px', color: '#ffffff' }} />,
              style: {
                color: '#ffffff',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
              },
            }}
          />
        </div>

        <Divider style={{ background: '#ffffff' }} />

        {/* Menu Section */}
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.to}
              onClick={() => handleMenuItemClick(item.to)}
              selected={activeItem === item.to}
              component={Link}
              to={item.to}
              sx={{
                color: '#ffffff',
                backgroundColor: activeItem === item.to ? '#ffffff' : 'transparent',
                '&.Mui-selected': {
                  backgroundColor: '#ffffff',
                  color: '#141414',
                  transform: 'scale(1.05)',
                  transition: 'all 0.3s ease',
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  transform: 'translateX(5px)',
                },
                padding: '15px 20px',
              }}
            >
              <ListItemIcon sx={{ color: activeItem === item.to ? '#141414' : '#ffffff' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={{
                  color: activeItem === item.to ? '#141414' : '#ffffff',
                }}
              />
            </ListItem>
          ))}
        </List>

        <Divider style={{ background: '#ffffff' }} />

        {/* Settings Section */}
        <List>
          {settingsItems.map((item) => (
            <ListItem
              button
              key={item.to}
              onClick={() => (item.action ? item.action() : handleMenuItemClick(item.to))}
              selected={activeItem === item.to}
              component={Link}
              to={item.to}
              sx={{
                color: '#ffffff',
                '&.Mui-selected': {
                  backgroundColor: '#ffffff',
                  color: '#141414',
                  transform: 'scale(1.05)',
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  transform: 'translateX(5px)',
                },
              }}
            >
              <ListItemIcon sx={{ color: '#ffffff' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>

        {/* Mobile Toggle Button */}
        {isMobile && (
          <IconButton
            onClick={toggleSidebar}
            sx={{
              position: 'absolute',
              top: 20,
              right: 10,
              color: '#ffffff',
            }}
          >
            <FaBars />
          </IconButton>
        )}
      </div>
    </Drawer>
  );
}

export default Sidebar;
