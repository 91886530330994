import React from 'react';
import './ExcelTable.css';

const ExcelTableFoodCost = ({ data, ingredients }) => {
  const calculateTotalCost = (productIngredients) => {
    return productIngredients.reduce((totalCost, ingredientDetail) => {
      const ingredient = ingredients.find(
        (ing) => ing.id === ingredientDetail.ingredientId
      );
      return ingredient
        ? totalCost + ingredient.price * ingredientDetail.quantity
        : totalCost;
    }, 0);
  };

  // Calculate additional fields for data
  const updatedData = data.map((item) => {
    const ht = (item.cost * 3).toFixed(2); // P.H.T = FoodCost * 3
    const ttc = (ht * 1.07).toFixed(2); // P.TTC = P.H.T * 1.07 (7% VAT)
    const realHT = (item.soldPrice / 1.07).toFixed(2); // P.R.H.T = P.Réel / 1.07
    const percentage = realHT && item.cost ? ((realHT / item.cost) ).toFixed(2) : 0; // Percentage
    const coef = realHT ? ((item.cost / realHT) * 100).toFixed(2) : 0; // Coefficient

    return {
      ...item,
      ht,
      ttc,
      realHT,
      percentage,
      coef,
    };
  });

  return (
    <div className="excel-table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th>Nom De Produit</th>
            <th>FoodCost</th>
            <th>P.H.T</th>
            <th>P.TTC</th>
            <th>P.Réel</th>
            <th>P.R.H.T</th>
            <th>Coef</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {updatedData.map((product) => (
            <tr key={product.id}>
              <td>{product.name}</td>
              <td>{product.cost}</td>
              <td>{product.ht}</td>
              <td>{product.ttc}</td>
              <td>{product.soldPrice.toFixed(2)}</td>
              <td>{product.realHT}</td>
              <td>{product.coef}</td>
              <td>{product.percentage}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelTableFoodCost;
