import React from 'react';
import './ExcelTable.css';

const ExcelTable4 = ({ data, title }) => {
  const types = data ? [...new Set(data.map(row => row.type))] : [];

  const groupedData = data.reduce((acc, row) => {
    const dateKey = row.date;
    if (!acc[dateKey]) {
      acc[dateKey] = {
        date: row.date,
        values: {},
      };
    }
    acc[dateKey].values[row.type] = (acc[dateKey].values[row.type] || 0) + row.value;
    return acc;
  }, {});

  // Sort grouped data by date (latest first)
  const groupedDataArray = Object.values(groupedData).sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const isEncaissement = title === "ENCAISSEMENT";

  return (
    
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2" className="header-cell">Date</th>
            <th colSpan={types.length + 1} className="header-cell">
              {title}
            </th>
          </tr>
          <tr>
            {types.map((type, index) => (
              <th key={index} className="sub-header-cell">{type || 'N/A'}</th>
            ))}
            <th className="sub-header-cell">Total</th>
          </tr>
        </thead>
        <tbody>
          {groupedDataArray.length > 0 ? (
            groupedDataArray.map((row, index) => {
              const totalValueForDate = Object.values(row.values).reduce((total, value) => total + value, 0);

              return (
                <tr key={index} className="data-row">
                  <td>{row.date || 'N/A'}</td>
                  {types.map((type, i) => {
                    const valueForType = row.values[type] || 0;
                    return <td key={i}>{valueForType || '0'}</td>;
                  })}
                  <td>{totalValueForDate || 'N/A'}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={types.length + 2} className="no-data">No data available</td>
            </tr>
          )}
        </tbody>
      </table>

  );
};

export default ExcelTable4;
