import React, { useState, useEffect } from "react";
import ExcelTable4 from "../Components/TableExcel/TableExcel";
import Encaissement from "../Services/Encaisement";
import {
  Card,
  CardContent,
  Typography,
  Tooltip,
  TextField,
  Button,
  TableContainer,
  Paper,
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const EncaissementsPage = () => {
  const [encaissements, setEncaissements] = useState([]);
  const [newEncaissement, setNewEncaissement] = useState({
    value: "",
    type: "",
    date: new Date().toISOString().split("T")[0],
    customType: "", // to hold the custom type value when "Other" is selected
  });
  const [editingEncaissement, setEditingEncaissement] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchType, setSearchType] = useState("");
  const restaurantId = localStorage.getItem("restaurantId");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");

  // Fetch all encaissements
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedEncaissements = await Encaissement.getEncaissementsByRestaurantId(restaurantId);
        setEncaissements(fetchedEncaissements);
      } catch (error) {
        console.error("Error fetching encaissements:", error.message);
      }
    };
    if (restaurantId) {
      fetchData();
    }
  }, [restaurantId]);

  const filteredEncaissements = encaissements.filter((encaissement) => {
    // Filter by type
    const typeFilter = searchType ? encaissement.type === searchType : true;
    
    // Filter by date range
    const dateDebutFilter = dateDebut ? new Date(encaissement.date) >= new Date(dateDebut) : true;
    const dateFinFilter = dateFin ? new Date(encaissement.date) <= new Date(dateFin) : true;
    
    return typeFilter && dateDebutFilter && dateFinFilter;
  });

  // Handlers
  const handleAddEncaissement = async () => {
    if (!newEncaissement.value || !newEncaissement.type || !newEncaissement.date) {
      alert("Please fill in all fields");
      return;
    }
    try {
      const encaissement = new Encaissement(
        parseFloat(newEncaissement.value),
        newEncaissement.type === "Other" ? newEncaissement.customType : newEncaissement.type,
        newEncaissement.date,
        restaurantId
      );
      await encaissement.addEncaissement();
      setEncaissements([...encaissements, encaissement]);
      setNewEncaissement({ value: "", type: "", date: new Date().toISOString().split("T")[0], customType: "" });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error adding encaissement:", error.message);
    }
  };

  const handleEditEncaissement = (encaissement) => {
    setEditingEncaissement(encaissement);
    setNewEncaissement({
      value: encaissement.value,
      type: encaissement.type,
      date: encaissement.date,
      customType: encaissement.customType || "", // preserve customType if it exists
    });
    setOpenDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      const updates = {
        value: newEncaissement.value,
        type: newEncaissement.type === "Other" ? newEncaissement.customType : newEncaissement.type,
        date: newEncaissement.date,
      };
      await Encaissement.updateEncaissement(editingEncaissement.id, updates);
      setEncaissements((prev) =>
        prev.map((enc) =>
          enc.id === editingEncaissement.id ? { ...enc, ...updates } : enc
        )
      );
      setEditingEncaissement(null);
      setNewEncaissement({ value: "", type: "", date: new Date().toISOString().split("T")[0], customType: "" });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving edit:", error.message);
    }
  };

  const handleDeleteEncaissement = async (id) => {
    try {
      await Encaissement.deleteEncaissement(id);
      setEncaissements((prev) => prev.filter((encaissement) => encaissement.id !== id));
    } catch (error) {
      console.error("Error deleting encaissement:", error.message);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#f4f6f9" }}>
      <Box
        sx={{
          marginBottom: 3,
          padding: 3,
          backgroundColor: "rgba(62, 250, 10, 0.15)",
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 700, color: "#4CAF50", textAlign: "center" }}>
          Encaissement Management
        </Typography>
      </Box>

      <Typography variant="h4" sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}>
        Encaissement Page
      </Typography>
      
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: 3,
        }}
      >
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Filter by Type</InputLabel>
          <Select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <MenuItem value="">All Types</MenuItem>
            {[...new Set(encaissements.map((e) => e.type))].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Date Début"
          type="date"
          value={dateDebut}
          onChange={(e) => setDateDebut(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />
        
        <TextField
          label="Date Fin"
          type="date"
          value={dateFin}
          onChange={(e) => setDateFin(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />
        
        <Button
          variant="contained"
          onClick={() => setOpenDialog(true)}
          sx={{
            backgroundColor: "#4CAF50",
            color: "#fff",
            float: "right",
            "&:hover": {
              backgroundColor: "#45A049",
            },
          }}
        >
          Add New Encaissement
        </Button>
      </Box>


      <Card 
  sx={{ 
    marginBottom: 3, 
    boxShadow: 2, 
    borderRadius: 3, 
    maxWidth: '78vw', // Ensures the card doesn't exceed the screen width
    overflow: 'hidden' // Hides any overflowing content
  }}
>
  <CardContent>
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
    <ExcelTable4 data={filteredEncaissements} title="Encaissements" />
    </div>
  </CardContent>
</Card>

      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: 'text.primary' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: 'text.primary' }}>Value</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: 'text.primary' }}>Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: 'text.primary' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEncaissements
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((enc) => (
                <TableRow key={enc.id} hover sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                  <TableCell sx={{ padding: '16px', fontSize: '1rem' }}>{enc.date}</TableCell>
                  <TableCell sx={{ padding: '16px', fontSize: '1rem' }}>{enc.value} TND</TableCell>
                  <TableCell sx={{ padding: '16px', fontSize: '1rem' }}>{enc.type}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleEditEncaissement(enc)}>
                        <EditIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDeleteEncaissement(enc.id)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredEncaissements.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog for adding/editing Encaissement */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{editingEncaissement ? "Edit Encaissement" : "Add New Encaissement"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Value"
            type="number"
            value={newEncaissement.value}
            onChange={(e) => setNewEncaissement({ ...newEncaissement, value: parseFloat(e.target.value) || ""  })}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Type</InputLabel>
            <Select
              value={newEncaissement.type}
              onChange={(e) =>
                setNewEncaissement({
                  ...newEncaissement,
                  type: e.target.value,
                  customType: e.target.value === "Other" ? newEncaissement.customType : "",
                })
              }
            >
              <MenuItem value="">Select Type</MenuItem>
    {[...new Set(encaissements.map((enc) => enc.type))].map((type) => (
      <MenuItem key={type} value={type}>
        {type}
      </MenuItem>
    ))}
    <MenuItem value="Other">Other</MenuItem> {/* Add "Other" option */}
             
            </Select>
          </FormControl>
          {newEncaissement.type === "Other" && (
            <TextField
              label="Custom Type"
              value={newEncaissement.customType}
              onChange={(e) => setNewEncaissement({ ...newEncaissement, customType: e.target.value })}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
          <TextField
            label="Date"
            type="date"
            value={newEncaissement.date}
            onChange={(e) => setNewEncaissement({ ...newEncaissement, date: e.target.value })}
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={editingEncaissement ? handleSaveEdit : handleAddEncaissement} color="primary">
            {editingEncaissement ? "Save Changes" : "Add Encaissement"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EncaissementsPage;
