import React, { useState, useEffect, useMemo } from "react";
import EncaissementService from "../Services/Encaisement"; // Encaissement service
import DecaissementService from "../Services/Decaisement"; // Decaissement service
import ExcelTable from "../Components/TableExcel/VaultTable"; // VaultTable component
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Card,
  CardContent,
  Pagination,
} from "@mui/material";

const CaisseTransactions = () => {
  // States for Encaissements and Decaissements
  const [encaissements, setEncaissements] = useState([]);
  const [decaissements, setDecaissements] = useState([]);
  const [dateDebut, setDateDebut] = useState(""); // State for start date
  const [dateFin, setDateFin] = useState(""); // State for end date
  const [filterType, setFilterType] = useState(""); // State for type filter
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination

  const rowsPerPage = 30; // Number of rows per page

  // Fetch Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedEncaissements = await EncaissementService.getAllEncaissements();
        const fetchedDecaissements = await DecaissementService.getAllDecaissements();

        // Set the data
        setEncaissements(fetchedEncaissements);
        setDecaissements(fetchedDecaissements);

        // Combine and set the initial filtered data
        const combinedData = [
          ...fetchedEncaissements.map((row) => ({ ...row, category: "ENCAISSEMENT" })),
          ...fetchedDecaissements.map((row) => ({ ...row, category: "DECAISSEMENT" })),
        ];
        setFilteredData(combinedData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  // Memoized combined data
  const combinedData = useMemo(() => {
    return [
      ...encaissements.map((row) => ({ ...row, category: "ENCAISSEMENT" })),
      ...decaissements.map((row) => ({ ...row, category: "DECAISSEMENT" })),
    ];
  }, [encaissements, decaissements]);

  // Filter data based on date range and type
  useEffect(() => {
    const filtered = combinedData.filter((row) => {
      const transactionDate = new Date(row.date);
      const startDate = new Date(dateDebut);
      const endDate = new Date(dateFin);

      const matchesDateRange =
        (!dateDebut || transactionDate >= startDate) &&
        (!dateFin || transactionDate <= endDate);
      const matchesType = filterType ? row.category === filterType : true;

      return matchesDateRange && matchesType;
    });

    // Conditionally hide "rapport" and "ecart" based on filterType
    const filteredWithHiddenColumns = filtered.map((row) => {
      if (filterType) {
        const { rapport, ecart, ...rest } = row;
        return rest; // Remove rapport and ecart fields if filtered by type
      }
      return row; // Return the full row if no filter is applied
    });

    setFilteredData(filteredWithHiddenColumns);
    setCurrentPage(1); // Reset to the first page on filter change
  }, [dateDebut, dateFin, filterType, combinedData]);

  // Paginate data
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, currentPage, rowsPerPage]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#f4f6f9" }}>
      <Box
        sx={{
          marginBottom: 3,
          padding: 3,
          backgroundColor: "#90caf9",
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, color: "#0d47a1", textAlign: "center" }}
        >
          Caisse Table
        </Typography>
      </Box>
      <Typography
        variant="h4"
        sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}
      >
        Caisse Page
      </Typography>

      {/* Search and Filter Inputs */}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          marginBottom: 3,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {/* Filter by Date Début */}
        <TextField
          label="Date Début"
          type="date"
          value={dateDebut}
          onChange={(e) => setDateDebut(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />

        {/* Filter by Date Fin */}
        <TextField
          label="Date Fin"
          type="date"
          value={dateFin}
          onChange={(e) => setDateFin(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />

        {/* Filter by Type */}
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            label="Type"
          >
            <MenuItem value="">All Types</MenuItem>
            <MenuItem value="ENCAISSEMENT">Encaissement</MenuItem>
            <MenuItem value="DECAISSEMENT">Decaissement</MenuItem>
          </Select>
        </FormControl>
      </Box>
     
      <Card 
  sx={{ 
    marginBottom: 3, 
    boxShadow: 2, 
    borderRadius: 3, 
    maxWidth: '78vw', // Ensures the card doesn't exceed the screen width
    overflow: 'hidden' // Hides any overflowing content
  }}
>
  <CardContent>
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <ExcelTable data={filteredData} />
    </div>
  </CardContent>
</Card>
      {/* Vault Table */}
     

      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 3,
        }}
      >
        <Pagination
          count={Math.ceil(filteredData.length / rowsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default CaisseTransactions;
