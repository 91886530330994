import { getFirestore, collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc, query, where } from 'firebase/firestore';

// Initialize Firestore
const db = getFirestore();

class Facture {
  constructor(fournisseurId, restaurantId, numero, achatHTVA, tvaDeductible, achatTTC, montantPaye, statut, resteAPayer, modeDePaiement, paymentnum, date = new Date()) {
    this.fournisseurId = fournisseurId; // Added fournisseurId
     // Added restaurantId
    this.numero = numero;
    this.achatHTVA = achatHTVA;
    this.tvaDeductible = tvaDeductible;
    this.achatTTC = achatTTC;
    this.montantPaye = montantPaye;
    this.statut = statut;
    this.resteAPayer = resteAPayer;
    this.modeDePaiement = modeDePaiement;
    this.paymentnum = paymentnum;
    this.date = date; // Added date
    this.restaurantId = restaurantId;
    this.id = ""; // Will be set after saving to Firestore
  }

  // Convert the object to Firestore document format
  toFirestore() {
    return {
      fournisseurId: this.fournisseurId, // Include fournisseurId in Firestore document
        // Include restaurantId in Firestore document
      numero: this.numero,
      achatHTVA: this.achatHTVA,
      tvaDeductible: this.tvaDeductible,
      achatTTC: this.achatTTC,
      montantPaye: this.montantPaye,
      statut: this.statut,
      resteAPayer: this.resteAPayer,
      modeDePaiement: this.modeDePaiement,
      paymentnum: this.paymentnum,
      date: this.date,
      restaurantId: this.restaurantId,  // Include date in Firestore document
    };
  }

  // Create and save a new facture to Firestore
  async saveFacture() {
    const factureRef = doc(collection(db, "factures"));
    this.id = factureRef.id; // Set the ID after saving
    await setDoc(factureRef, this.toFirestore());
  }

  // Static method to get a facture by its ID
  static async getFactureById(factureId) {
    const factureRef = doc(db, "factures", factureId);
    const factureSnapshot = await getDoc(factureRef);
    if (factureSnapshot.exists()) {
      return { id: factureSnapshot.id, ...factureSnapshot.data() };
    } else {
      throw new Error("Facture not found");
    }
  }
// Static method to update a facture by its ID with validation
static async updateFacture(factureId, updatedData) {
  try {
    // Assuming you're using Firestore or another database
    await getFirestore.collection('factures').doc(factureId).update(updatedData);
  } catch (error) {
    console.error("Error updating facture in Firestore:", error);
    throw error; // Ensure the error is propagated to the calling function
  }
}


  // Static method to delete a facture by its ID
  static async deleteFacture(factureId) {
    const factureRef = doc(db, "factures", factureId);
    await deleteDoc(factureRef);
  }

  // Static method to get all factures
  static async getAllFactures() {
    const facturesRef = collection(db, "factures");
    const querySnapshot = await getDocs(facturesRef);
    const factures = [];
    querySnapshot.forEach((doc) => {
      factures.push({ id: doc.id, ...doc.data() });
    });
    return factures;
  }

// Static method to update a facture by its ID and fournisseurId with validation
static async updateFacture(factureId, fournisseurId, updatedData) {
  try {
    const factureRef = doc(db, "factures", factureId);
    const factureSnapshot = await getDoc(factureRef);
    
    if (!factureSnapshot.exists()) {
      throw new Error("Facture not found");
    }

    const factureData = factureSnapshot.data();

    // Ensure that the fournisseurId matches the one in the Firestore document
    if (factureData.fournisseurId !== fournisseurId) {
      throw new Error("Fournisseur ID does not match the one in the facture");
    }

    // Merge updated data with existing data
    const mergedData = { ...factureData, ...updatedData };

    // Update the facture with the new data
    await updateDoc(factureRef, mergedData);
    console.log("Facture updated successfully");
  } catch (error) {
    console.error("Error updating facture in Firestore:", error);
    throw error; // Ensure the error is propagated to the calling function
  }
}


  // Static method to get all factures for a specific fournisseur and restaurant
  static async getFacturesByFournisseurAndRestaurant(fournisseurId, restaurantId) {
    const facturesRef = collection(db, "factures");
    const q = query(
      facturesRef, 
      where("fournisseurId", "==", fournisseurId), 
      where("restaurantId", "==", restaurantId)
    );
    const querySnapshot = await getDocs(q);
    
    const factures = [];
    querySnapshot.forEach((doc) => {
      factures.push({ id: doc.id, ...doc.data() });
    });
    return factures;
  }

  // Static method to get all factures for a specific fournisseur
  static async getFacturesByFournisseurId(fournisseurId) {
    const facturesRef = collection(db, "factures");
    const querySnapshot = await getDocs(facturesRef);
    const factures = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.fournisseurId === fournisseurId) {
        factures.push({ id: doc.id, ...data });
      }
    });
    return factures;
  }
}

export default Facture; 