import React, { useState, useEffect ,useMemo } from "react";
import { Link } from "react-router-dom";
import EncaissementService from "../Services/Encaisement";
import DecaissementService from "../Services/Decaisement";
import { Card, CardContent, Typography, Box, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ExcelTable from "../Components/TableExcel/VaultTable";

const VaultPage = () => {
  const [encaissements, setEncaissements] = useState([]);
  const [decaissements, setDecaissements] = useState([]);
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedEncaissements = await EncaissementService.getAllEncaissements();
        const fetchedDecaissements = await DecaissementService.getAllDecaissements();

        setEncaissements(fetchedEncaissements);
        setDecaissements(fetchedDecaissements);

        const combinedData = [
          ...fetchedEncaissements.map((row) => ({ ...row, category: "ENCAISSEMENT" })),
          ...fetchedDecaissements.map((row) => ({ ...row, category: "DECAISSEMENT" })),
        ];
        setFilteredData(combinedData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const combinedData = useMemo(() => {
    return [
      ...encaissements.map((row) => ({ ...row, category: "ENCAISSEMENT" })),
      ...decaissements.map((row) => ({ ...row, category: "DECAISSEMENT" })),
    ];
  }, [encaissements, decaissements]);

  useEffect(() => {
    const filtered = combinedData.filter((row) => {
      const transactionDate = new Date(row.date);
      const startDate = dateDebut ? new Date(dateDebut) : null;
      const endDate = dateFin ? new Date(dateFin) : null;

      const matchesDateRange =
        (!startDate || transactionDate >= startDate) &&
        (!endDate || transactionDate <= endDate);
      const matchesType = filterType ? row.category === filterType : true;

      return matchesDateRange && matchesType;
    });

    setFilteredData(filtered);
  }, [dateDebut, dateFin, filterType, combinedData]);

  return (
    <div style={{ padding: "20px", backgroundColor: "#f4f6f9" }}>
      <Box sx={{ marginBottom: 3, padding: 3, backgroundColor: "#2196f3", borderRadius: 2, boxShadow: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: "700", color: "#fff", textAlign: "center" }}>
          Caisse Table
        </Typography>
      </Box>

      <Typography variant="h4" sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}>
        Vault Page
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: 3,
        }}
      >
        <TextField
          label="Date Début"
          type="date"
          value={dateDebut}
          onChange={(e) => setDateDebut(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />
        <TextField
          label="Date Fin"
          type="date"
          value={dateFin}
          onChange={(e) => setDateFin(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ minWidth: 200 }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Type</InputLabel>
          <Select value={filterType} onChange={(e) => setFilterType(e.target.value)} label="Type">
            <MenuItem value="">All Types</MenuItem>
            <MenuItem value="ENCAISSEMENT">Encaissement</MenuItem>
            <MenuItem value="DECAISSEMENT">Decaissement</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Card 
  sx={{ 
    marginBottom: 3, 
    boxShadow: 2, 
    borderRadius: 3, 
    maxWidth: '78vw', // Ensures the card doesn't exceed the screen width
    overflow: 'hidden' // Hides any overflowing content
  }}
>
  <CardContent>
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <ExcelTable data={filteredData} />
    </div>
  </CardContent>
</Card>

   

      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ backgroundColor: "rgba(62, 250, 10, 0.15)", borderRadius: 2, boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "600", color: "#4CAF50", textAlign: "center" }}>
                Encaissements Table
              </Typography>
              <Box textAlign="center" mt={2}>
                <Link to="/Encaissement" style={{ textDecoration: "none" }}>
                  <Button variant="contained" color="success">
                    Ajouter Encaissement
                  </Button>
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ backgroundColor: "rgba(223, 28, 28, 0.15)", borderRadius: 2, boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "600", color: "#d32f2f", textAlign: "center" }}>
                Decaissements Table
              </Typography>
              <Box textAlign="center" mt={2}>
                <Link to="/Decaissement" style={{ textDecoration: "none" }}>
                  <Button variant="contained" color="error">
                    Ajouter Decaissement
                  </Button>
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card sx={{ backgroundColor: "#90caf9", borderRadius: 2, boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "600", color: "#0d47a1", textAlign: "center" }}>
                Toutes les Caisses
              </Typography>
              <Box textAlign="center" mt={2}>
                <Link to="/AllVault" style={{ textDecoration: "none" }}>
                  <Button variant="outlined" color="primary">
                    Voir Plus ...
                  </Button>
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default VaultPage;
