import React, { useState, useEffect } from "react";
import Decaissement from "../Services/Decaisement"; // Service for API calls
import ExcelTable4 from "../Components/TableExcel/TableExcel"; // Export to Excel Component
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Tooltip,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const DecaissementsPage = () => {
  const [decaissements, setDecaissements] = useState([]);
  const [newDecaissement, setNewDecaissement] = useState({
    value: "",
    type: "",
    date: new Date().toISOString().split("T")[0],
  });
  const [editingDecaissement, setEditingDecaissement] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [existingTypes, setExistingTypes] = useState([]);
  const [newType, setNewType] = useState("");
    const [searchType, setSearchType] = useState("");
    const restaurantId = localStorage.getItem("restaurantId");
    const [dateDebut, setDateDebut] = useState("");
    const [dateFin, setDateFin] = useState("");

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch decaissements by restaurant ID
        const fetchedDecaissements = await Decaissement.getDecaissementsByRestaurantId(restaurantId);
        setDecaissements(fetchedDecaissements);

        // Fetch existing types
        const fetchedTypes = await Decaissement.getExistingTypes();
        setExistingTypes(fetchedTypes);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [restaurantId]);
  const filteredDecaissements = decaissements.filter((decaissement) => {
    // Filter by type
    const typeFilter = searchType ? decaissement.type === searchType : true;
    
    // Filter by date range
    const dateDebutFilter = dateDebut ? new Date(decaissement.date) >= new Date(dateDebut) : true;
    const dateFinFilter = dateFin ? new Date(decaissement.date) <= new Date(dateFin) : true;
    
    return typeFilter && dateDebutFilter && dateFinFilter;
  });

  const handleAddDecaissement = async () => {
    if (!newDecaissement.value || !newDecaissement.type || !newDecaissement.date) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const typeToUse = newDecaissement.type === "newType" ? newType : newDecaissement.type;
      const decaissement = new Decaissement(
        parseFloat(newDecaissement.value),
        typeToUse,
        newDecaissement.date,
        restaurantId
      );
      await decaissement.addDecaissement();
      setDecaissements([...decaissements, decaissement]);

      setNewDecaissement({
        value: "",
        type: "",
        date: new Date().toISOString().split("T")[0],
      });
      setNewType("");
      setOpenDialog(false);
    } catch (error) {
      console.error("Error adding decaissement:", error.message);
    }
  };

  const handleEditDecaissement = (decaissement) => {
    setEditingDecaissement(decaissement);
    setNewDecaissement({
      value: decaissement.value,
      type: decaissement.type,
      date: decaissement.date,
    });
    setOpenDialog(true);
  };

  const handleSaveEdit = async () => {
    if (!newDecaissement.value || !newDecaissement.type || !newDecaissement.date) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const updates = {
        value: newDecaissement.value,
        type: newDecaissement.type,
        date: newDecaissement.date,
        restaurantId,
      };

      await Decaissement.updateDecaissement(editingDecaissement.id, updates);

      setDecaissements((prev) =>
        prev.map((dec) =>
          dec.id === editingDecaissement.id ? { ...dec, ...updates } : dec
        )
      );

      setEditingDecaissement(null);
      setNewDecaissement({
        value: "",
        type: "",
        date: new Date().toISOString().split("T")[0],
      });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving edit:", error.message);
    }
  };

  const handleDeleteDecaissement = async (id) => {
    try {
      await Decaissement.deleteDecaissement(id);
      setDecaissements((prev) =>
        prev.filter((decaissement) => decaissement.id !== id)
      );
    } catch (error) {
      console.error("Error deleting decaissement:", error.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    
      <div style={{ padding: "20px", backgroundColor: "#f4f6f9" }}>
      <Box
        sx={{
          marginBottom: 3,
          padding: 3,
          backgroundColor: "rgba(223, 28, 28, 0.15)",
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 700, color: "#d32f2f", textAlign: "center" }}>
          Decaissement Management
        </Typography>
      </Box>
       <Typography variant="h4" sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}>
       Decaissement Page
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
                justifyContent: "center",
                marginBottom: 3,
              }}
            >
              <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                <InputLabel>Filter by Type</InputLabel>
                <Select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                  <MenuItem value="">All Types</MenuItem>
                  {[...new Set(decaissements.map((e) => e.type))].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
      
              <TextField
                label="Date Début"
                type="date"
                value={dateDebut}
                onChange={(e) => setDateDebut(e.target.value)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ minWidth: 200 }}
              />
              
              <TextField
                label="Date Fin"
                type="date"
                value={dateFin}
                onChange={(e) => setDateFin(e.target.value)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ minWidth: 200 }}
              />
              
              <Button
                variant="contained"
                onClick={() => setOpenDialog(true)}
                sx={{
                  backgroundColor: "#d32f2f",
                  color: "#fff",
                  float: "right",
                  "&:hover": {
                    backgroundColor: "#b82828",
                  },
                }}
              >
                Add New Decaissement
              </Button>
            </Box>
      
     
      <Card 
  sx={{ 
    marginBottom: 3, 
    boxShadow: 2, 
    borderRadius: 3, 
    maxWidth: '78vw', // Ensures the card doesn't exceed the screen width
    overflow: 'hidden' // Hides any overflowing content
  }}
>
  <CardContent>
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
    <ExcelTable4 data={filteredDecaissements} title="DECAISSEMENT" />
    </div>
  </CardContent>
</Card>
     
   

      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
  <Table sx={{ minWidth: 650 }}>
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: 'text.primary' }}>Date</TableCell>
        <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: 'text.primary' }}>Valeur</TableCell>
        <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: 'text.primary' }}>Type</TableCell>
        <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: 'text.primary' }}>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredDecaissements
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((decaissement) => (
          <TableRow key={decaissement.id} hover sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
            <TableCell sx={{ padding: '16px', fontSize: '1rem' }}>{decaissement.date}</TableCell>
            <TableCell sx={{ padding: '16px', fontSize: '1rem' }}>{decaissement.value} TND</TableCell>
            <TableCell sx={{ padding: '16px', fontSize: '1rem' }}>{decaissement.type}</TableCell>
            <TableCell>
              <Tooltip title="Edit">
                <IconButton color="primary" onClick={() => handleEditDecaissement(decaissement)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton color="secondary" onClick={() => handleDeleteDecaissement(decaissement.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>
</TableContainer>

<TablePagination
  rowsPerPageOptions={[5, 10, 25]}
  component="div"
  count={filteredDecaissements.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {editingDecaissement ? "Edit Decaissement" : "Add Decaissement"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Valeur"
            type="number"
            value={newDecaissement.value}
            onChange={(e) =>{
              const parsedValue = parseFloat(e.target.value);
              setNewDecaissement({ ...newDecaissement, value: isNaN(parsedValue) ? "" : parsedValue  })
            }}
            fullWidth
            sx={{ marginBottom: "10px" }}
          />
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <InputLabel>Type</InputLabel>
            <Select
              value={newDecaissement.type}
              onChange={(e) => {
                const selectedType = e.target.value;
                setNewDecaissement({
                  ...newDecaissement,
                  type: selectedType === "" ? "" : selectedType,
                });
              }}
            >
              {/* Extract unique types from decaissements to avoid duplicates */}
              {[...new Set(decaissements.map((decaissement) => decaissement.type))]
                .map((uniqueType) => (
                  <MenuItem key={uniqueType} value={uniqueType}>
                    {uniqueType}
                  </MenuItem>
                ))}
              <MenuItem value="newType">Ajouter un nouveau type</MenuItem>
            </Select>
            {newDecaissement.type === "newType" && (
              <TextField
                label="New Type"
                variant="outlined"
                fullWidth
                value={newType}
                onChange={(e) => setNewType(e.target.value)}
                sx={{ marginTop: "10px" }}
              />
            )}
          </FormControl>
          <TextField
            label="Date"
            type="date"
            value={newDecaissement.date}
            onChange={(e) =>
              setNewDecaissement({ ...newDecaissement, date: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            sx={{ marginBottom: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Annuler
          </Button>
          <Button
            onClick={editingDecaissement ? handleSaveEdit : handleAddDecaissement}
            color="primary"
          >
            {editingDecaissement ? "Enregistrer" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DecaissementsPage;
