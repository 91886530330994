import { useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Typography, Button, Box, Divider, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

function PdfTemplate() {
    const ref = useRef();
    const [openAirPopup, setAirPopup] = useState(false);
    const [openForm, setOpenForm] = useState(true);
    const [clientName, setClientName] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [clientTaxNumber, setClientTaxNumber] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [date, setDate] = useState('');
    const [TVA, setTVA ] = useState('');
    const [item, setItem] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [unit, setUnit] = useState('unit');
    const [unitPrice, setUnitPrice] = useState('');
    const [list, setList] = useState([]);

    const addData = () => {
        if (item && quantity > 0 && unitPrice > 0) {
            setList([
                ...list,
                { 
                    product: item, 
                    quantity: parseInt(quantity), 
                    unit, 
                    unitPrice: parseFloat(unitPrice), 
                    totalHT: parseFloat(quantity) * parseFloat(unitPrice) 
                }
            ]);
            setItem('');
            setQuantity(1);
            setUnit('unit');
            setUnitPrice('');
            setAirPopup(false);
        }
    };

    const sumHT = list.reduce((sum, { totalHT }) => sum + totalHT, 0);
    const tva = sumHT * (TVA / 100);
    const timbre = 1;
    const totalTTC = sumHT + tva + timbre;

    const printContent = () => {
        const content = ref.current;
        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(content.innerHTML);
        printWindow.document.close();
        printWindow.print();
    };

    const handleSubmit = () => {
        setOpenForm(false);
    };

    return (
        <>
            {/* Client Information Form */}
            {openForm && (
                <Box sx={{ padding: 3, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3 }}>
                    <Typography variant="h4" color="primary" fontWeight="bold" gutterBottom>
                        Facture - Création
                    </Typography>
                    <TextField
                        label="Nom du Client"
                        variant="outlined"
                        fullWidth
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        label="Adresse"
                        variant="outlined"
                        fullWidth
                        value={clientAddress}
                        onChange={(e) => setClientAddress(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        label="Numéro de TVA"
                        variant="outlined"
                        fullWidth
                        value={clientTaxNumber}
                        onChange={(e) => setClientTaxNumber(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        label="Numéro de Facture"
                        variant="outlined"
                        fullWidth
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        label="Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
                        <InputLabel>TVA</InputLabel>
                        <Select
                            label="TVA"
                            value={TVA}
                            onChange={(e) => setTVA(e.target.value)}
                        >
                            <MenuItem value={19}>19%</MenuItem>
                            <MenuItem value={7}>7%</MenuItem>
                        </Select>
                    </FormControl>
                    <Box textAlign="center" mt={2}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Créer la Facture</Button>
                    </Box>
                </Box>
            )}

            {/* PDF Template for Invoice */}
            {!openForm && (
                <Box ref={ref} sx={{ padding: 3, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3 }}>
                    {/* Header Section */}
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="h4" color="primary" fontWeight="bold">Optimizi ERP</Typography>
                            <Typography variant="body1">Matricule Fiscal: {clientTaxNumber}</Typography>
                            <Typography variant="body1">Adresse: {clientAddress}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                            <Typography variant="h5" color="primary">Client: {clientName}</Typography>
                            <Typography variant="body1">Numéro de TVA: {clientTaxNumber}</Typography>
                            <Typography variant="body1">Adresse: {clientAddress}</Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ margin: '20px 0' }} />

                    {/* Invoice Title */}
                    <Box textAlign="center" mb={3}>
                        <Typography variant="h3" color="primary" fontWeight="bold">FACTURE</Typography>
                        <Typography variant="h5">Numéro de Facture: {invoiceNumber}</Typography>
                    </Box>

                    {/* Table with Products */}
                    <Box mb={3}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: '10px', borderBottom: '2px solid #ccc', textAlign: 'left' }}>Désignation</th>
                                    <th style={{ padding: '10px', borderBottom: '2px solid #ccc', textAlign: 'center' }}>Quantité</th>
                                    <th style={{ padding: '10px', borderBottom: '2px solid #ccc', textAlign: 'center' }}>Unité</th>
                                    <th style={{ padding: '10px', borderBottom: '2px solid #ccc', textAlign: 'right' }}>PU HT</th>
                                    <th style={{ padding: '10px', borderBottom: '2px solid #ccc', textAlign: 'right' }}>Total HT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.length > 0 ? list.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{item.product}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc', textAlign: 'center' }}>{item.quantity}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc', textAlign: 'center' }}>{item.unit}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc', textAlign: 'right' }}>TND {item.unitPrice.toFixed(2)}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc', textAlign: 'right' }}>TND {item.totalHT.toFixed(2)}</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ccc' }}>
                                            <Typography variant="body1">Aucun produit ajouté.</Typography>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan="4" style={{ padding: '10px', borderTop: '2px solid #ccc' }}>
                                        <Typography variant="body1" fontWeight="bold">Total HT :</Typography>
                                        <Typography variant="body1" fontWeight="bold">Total TVA ({TVA}%) :</Typography>
                                        <Typography variant="body1" fontWeight="bold">Timbre :</Typography>
                                    </td>
                                    <td style={{ padding: '10px', borderTop: '2px solid #ccc', textAlign: 'right' }}>
                                        <Typography variant="body1">TND {sumHT.toFixed(2)}</Typography>
                                        <Typography variant="body1">TND {tva.toFixed(2)}</Typography>
                                        <Typography variant="body1">TND {timbre.toFixed(2)}</Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" style={{ padding: '10px', borderTop: '2px solid #ccc', textAlign: 'right', color: '#F81D2D' }}>
                                        <Typography variant="h5" fontWeight="bold">Total TTC : TND {totalTTC.toFixed(2)}</Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>

                    {/* Print and Add Product Buttons */}
                    <Box mt={3} textAlign="center">
                        <Button variant="contained" color="primary" onClick={printContent}>Imprimer</Button>
                    </Box>
                    <Box mt={3} textAlign="center">
                        <Button variant="contained" color="secondary" onClick={() => setAirPopup(true)}>Ajouter un Produit</Button>
                    </Box>
                </Box>
            )}

            {/* Add Product Dialog */}
            <Dialog open={openAirPopup} onClose={() => setAirPopup(false)}>
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Typography variant="h6">Ajouter un produit</Typography>
                        <Button onClick={() => setAirPopup(false)}><Close /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField label="Produit" fullWidth value={item} onChange={(e) => setItem(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Quantité" type="number" fullWidth value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Unité" fullWidth value={unit} onChange={(e) => setUnit(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Prix Unitaire (HT)" type="number" fullWidth value={unitPrice} onChange={(e) => setUnitPrice(e.target.value)} />
                        </Grid>
                    </Grid>
                    <Box mt={2} textAlign="center">
                        <Button variant="contained" color="primary" onClick={addData}>Ajouter</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default PdfTemplate;
