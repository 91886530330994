import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Firebase/Config"; // Ensure that you import your Firebase config
import Sidebar from './Components/SideBar/SideBar'; // Import the Sidebar component
import Auth from "./Pages/Auth";
import Profile from "./Pages/UserProfile";
import Dashboard from "./Pages/HomePage";
import AddRestaurantForm from "./Pages/RestaurantPage";
import IngredientsPage from "./Pages/Ingredient";
import FoodCostPage from "./Pages/FoodCost";
import EncaissementsPage from "./Pages/Encaissement";
import DecaissementsPage from "./Pages/Decaissement";
import RHPage from "./Pages/RH";
import PartenairesPage from "./Pages/Partenaire";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import VaultPage from "./Pages/VaultPage";
import Analytics from "./Pages/Analytics";
import FournisseurPage from "./Pages/Fournisseur";
import PdfTemplate from "./Pages/Facture";
import ExcelSearchScreen from "./Pages/RHEXCEL";
import WorkerDetails from "./Pages/RHWorker";
import CaisseTransactions from "./Pages/CaisseTransaction";




const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',  // Set a primary color, e.g., blue
    },
    secondary: {
      main: '#ff4081',  // Set a secondary color, e.g., pink
    },
  },
  typography: {
    // Add your custom typography options here
  },
  // Add other global theme configurations
});

const App = () => {
    const user = JSON.parse(localStorage.getItem("user"));
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup the subscription on unmount
    return unsubscribe;
  }, []);

  // If loading, show a loading message or spinner
  if (loading) {
    return <div>Loading...</div>; // Consider a better UI here, like a spinner
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MainContent currentUser={currentUser} />
      </Router>
    </ThemeProvider>
  );
};

const ProtectedRoute = ({ currentUser, children }) => {
  return currentUser ? children : <Navigate to="/" />;
};

const MainContent = ({ currentUser }) => {
  const location = useLocation();  // Get current route

  return (
    <div style={{ display: 'flex' }}>
      {/* Only show the sidebar if the user is authenticated and not on the /AddResto page */}
      {currentUser && location.pathname !== "/AddResto" && <Sidebar />}
      
      {/* Main content area */}
      <div style={{ flex: 1 }}>
        <Routes>
          {/* Authentication Page */}
          <Route path="/" element={currentUser ? <Navigate to="/Home" /> : <Auth />} />

          {/* Dashboard Page, protected route */}
          <Route
            path="/Home"
            element={<ProtectedRoute currentUser={currentUser}><Dashboard/></ProtectedRoute>}
          />
          <Route
            path="/AddResto"
            element={<ProtectedRoute currentUser={currentUser}><AddRestaurantForm /></ProtectedRoute>}
          />
        
            <Route
            path="/excel"
            element={<ProtectedRoute currentUser={currentUser}><ExcelSearchScreen /></ProtectedRoute>}
          />
          <Route
            path="/Encaissement"
            element={<ProtectedRoute currentUser={currentUser}><EncaissementsPage /></ProtectedRoute>}
          />
        
          <Route
            path="/FoodCost"
            element={<ProtectedRoute currentUser={currentUser}><FoodCostPage /></ProtectedRoute>}
          />
          <Route
            path="/Ingredients"
            element={<ProtectedRoute currentUser={currentUser}><IngredientsPage /></ProtectedRoute>}
          />
          {/* Profile Page, another protected route */}
          <Route
            path="/profile"
            element={<ProtectedRoute currentUser={currentUser}><Profile /></ProtectedRoute>}
          />
          <Route
            path="/Decaissement"
            element={<ProtectedRoute currentUser={currentUser}><DecaissementsPage /></ProtectedRoute>}
          />
          <Route
            path="/Vault"
            element={<ProtectedRoute currentUser={currentUser}><VaultPage /></ProtectedRoute>}
          />
          <Route
            path="/Analytics"
            element={<ProtectedRoute currentUser={currentUser}><Analytics /></ProtectedRoute>}
          />
          <Route
            path="/fournisseurs"
            element={<ProtectedRoute currentUser={currentUser}><FournisseurPage /></ProtectedRoute>}
          />
          <Route
            path="/RH"
            element={<ProtectedRoute currentUser={currentUser}><RHPage /></ProtectedRoute>}
          />
          <Route
            path="/Partenaire"
            element={<ProtectedRoute currentUser={currentUser}><PartenairesPage /></ProtectedRoute>}
          />
        
            <Route path="/worker/:workerId" element={<WorkerDetails />} />
            <Route path="/Facture" element={<PdfTemplate/>} />
            
            <Route path="/AllVault" element={<CaisseTransactions />} /> {/* Details page route */}
        </Routes>
      </div>
    </div>
  );
};

export default App;
