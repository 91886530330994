import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Partenaire from "../Services/Partenaire";
import ExcelTablePartenaire from "../Components/TableExcel/ExcelPartenaire";
import MontantDateStatueList from "../Components/MontantStauteComponent";

const PartenairesPage = () => {
  const [partenaires, setPartenaires] = useState([]);
  const [selectedPartenaire, setSelectedPartenaire] = useState(null);
  const [encaissements, setEncaissements] = useState([]);
  const [newPartenaire, setNewPartenaire] = useState({ name: "", image: "", commission: "" });
  const [editingPartenaire, setEditingPartenaire] = useState(null);
  const [montantDateStatueEntries, setMontantDateStatueEntries] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); // State to control Dialog visibility
  const [showMontantDateStatue, setShowMontantDateStatue] = useState(false); // State to control visibility of MontantDateStatueList and ExcelTablePartenaire
  const restaurantId = localStorage.getItem("restaurantId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedPartenaires = await Partenaire.getAllPartenaires(restaurantId);
        setPartenaires(fetchedPartenaires);
      } catch (error) {
        console.error("Error fetching partenaires:", error.message);
      }
    };
    fetchData();
  }, [restaurantId]);

  const handleAddPartenaire = async () => {
    try {
      const partenaire = new Partenaire(newPartenaire.name, newPartenaire.image, newPartenaire.commission, restaurantId);
      await partenaire.addPartenaire();
      setPartenaires([...partenaires, { ...newPartenaire, id: partenaire.id }]);
      setNewPartenaire({ name: "", image: "", commission: "" });
      setOpenDialog(false); // Close the dialog after adding
    } catch (error) {
      console.error("Error adding partenaire:", error.message);
    }
  };

  const handleEditPartenaire = (partenaire) => {
    setEditingPartenaire(partenaire);
    setNewPartenaire({ name: partenaire.name, image: partenaire.image, commission: partenaire.commission });
  };

  const handleSaveEditPartenaire = async () => {
    try {
      const updatedPartenaire = new Partenaire(newPartenaire.name, newPartenaire.image, newPartenaire.commission, restaurantId);
      await updatedPartenaire.updatePartenaire(editingPartenaire.id);
      const updatedList = partenaires.map((partenaire) =>
        partenaire.id === editingPartenaire.id ? { ...partenaire, ...newPartenaire } : partenaire
      );
      setPartenaires(updatedList);
      setEditingPartenaire(null);
      setNewPartenaire({ name: "", image: "", commission: "" });
    } catch (error) {
      console.error("Error updating partenaire:", error.message);
    }
  };

  const handleDeletePartenaire = async (id) => {
    try {
      await Partenaire.deletePartenaire(id, restaurantId);
      setPartenaires(partenaires.filter((partenaire) => partenaire.id !== id));
    } catch (error) {
      console.error("Error deleting partenaire:", error.message);
    }
  };

  const handleViewEncaissements = async (name) => {
    try {
      const fetchedEncaissements = await Partenaire.getEncaissementsForPartenaire(name, restaurantId);
      setEncaissements(fetchedEncaissements);
      setSelectedPartenaire(name);
    } catch (error) {
      console.error("Error fetching encaissements:", error.message);
    }
  };

  const handleShowMontantDateStatue = (partenaireId) => {
    setSelectedPartenaire(partenaireId);  // Set the selected partenaireId
    setShowMontantDateStatue(true);        // Show MontantDateStatueList
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" gutterBottom align="center" sx={{ color: "#3f51b5", fontWeight: 700 }}>
        Partenaires Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDialog(true)} // Open the dialog on button click
        sx={{ mb: 4 }}
      >
        Add Partenaire
      </Button>

      {/* Dialog for adding a new partenaire */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{editingPartenaire ? "Edit Partenaire" : "Add Partenaire"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={newPartenaire.name}
                onChange={(e) => setNewPartenaire({ ...newPartenaire, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Image URL"
                variant="outlined"
                fullWidth
                value={newPartenaire.image}
                onChange={(e) => setNewPartenaire({ ...newPartenaire, image: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Commission"
                variant="outlined"
                fullWidth
                type="number"
                value={newPartenaire.commission}
                onChange={(e) => setNewPartenaire({ ...newPartenaire, commission: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">Cancel</Button>
          {editingPartenaire ? (
            <Button variant="contained" color="primary" onClick={handleSaveEditPartenaire}>
              Save Changes
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleAddPartenaire}>
              Add Partenaire
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        {partenaires
          .filter((partenaire) => partenaire.restaurantId === restaurantId) // Filter based on restaurantId
          .map((partenaire) => (
            <Grid item xs={12} sm={6} md={4} key={partenaire.id}>
              <Card sx={{ borderRadius: 4, boxShadow: 3 }}>
                <CardContent>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Avatar
                      src={partenaire.image}
                      alt={partenaire.name}
                      sx={{ width: 100, height: 100, mb: 2 }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      {partenaire.name}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleViewEncaissements(partenaire.name)}
                      fullWidth
                      sx={{ mb: 1 }}
                    >
                      View Encaissements
                    </Button>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => handleEditPartenaire(partenaire)}
                      fullWidth
                      sx={{ mb: 1 }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeletePartenaire(partenaire.id)}
                      fullWidth
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleShowMontantDateStatue(partenaire.id)} // Pass partenaire id here
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      View Montant Date Statue Entries
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>

      {selectedPartenaire && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">
            Selected Partenaire ID: {selectedPartenaire}
          </Typography>
          <MontantDateStatueList partenaireId={selectedPartenaire} />

        </Box>
        
      )}

      <ExcelTablePartenaire
        partenairesData={partenaires}
        encaissement={encaissements}
        montantDateStatueEntries={montantDateStatueEntries}
        selectedPartenaire={selectedPartenaire} // Pass the selectedPartenaire ID
      />
    </Container>
  );
};

export default PartenairesPage;
