import { getFirestore, doc, setDoc, getDoc, updateDoc, deleteDoc, collection, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const db = getFirestore();
const storage = getStorage();

class Product {
  constructor(name, soldPrice, restaurantId, image = null, ingredients = []) {
    this.name = name;
    this.soldPrice = soldPrice;
    this.restaurantId = restaurantId; // Added restaurantId
    this.image = image; // File object
    this.ingredients = ingredients; // Array of { ingredientId, quantity }
    this.imageURL = null;
    this.id = null; // Will be set after adding the product
    this.cost = 0; // Calculated based on ingredients
  }

  // Calculate the cost of the product
  async calculateCost() {
    try {
      let totalCost = 0;
      for (const ingredient of this.ingredients) {
        const ingredientRef = doc(db, "ingredients", ingredient.ingredientId);
        const ingredientSnap = await getDoc(ingredientRef);

        if (!ingredientSnap.exists()) {
          throw new Error(`Ingredient with ID ${ingredient.ingredientId} not found.`);
        }

        const ingredientData = ingredientSnap.data();
        const ingredientCost = ingredientData.pricePerUnit * ingredient.quantity;
        totalCost += ingredientCost;
      }
      this.cost = totalCost;
      return totalCost;
    } catch (error) {
      console.error("Error calculating product cost:", error.message);
      throw new Error(error.message);
    }
  }

  // Add a new product
  async addProduct() {
    try {
      const productRef = doc(collection(db, "products"));

      // Upload image if provided
      if (this.image) {
        const imageRef = ref(storage, `product_images/${productRef.id}`);
        await uploadBytes(imageRef, this.image);
        this.imageURL = await getDownloadURL(imageRef);
      }

      await this.calculateCost();

      await setDoc(productRef, {
        name: this.name,
        soldPrice: this.soldPrice,
        restaurantId: this.restaurantId,  // Store restaurantId in Firestore
        image: this.imageURL,
        ingredients: this.ingredients,
        cost: this.cost,
      });

      this.id = productRef.id;
      console.log("Product added successfully!");
    } catch (error) {
      console.error("Error adding product:", error.message);
      throw new Error(error.message);
    }
  }

  // Ingredient class
  static async updateIngredient(ingredientId, updates) {
    try {
      const ingredientRef = doc(db, "ingredients", ingredientId);
      const ingredientSnap = await getDoc(ingredientRef);

      if (!ingredientSnap.exists()) {
        throw new Error("Ingredient does not exist.");
      }

      // Update the ingredient
      await updateDoc(ingredientRef, updates);
      console.log("Ingredient updated successfully!");

      // After updating, we need to update products using this ingredient
      await Product.updateProductsByIngredient(ingredientId);

    } catch (error) {
      console.error("Error updating ingredient:", error.message);
      throw new Error(error.message);
    }
  }

  // Product class
  static async updateProductsByIngredient(ingredientId) {
    try {
      // Get all products
      const productsSnapshot = await getDocs(collection(db, "products"));
      const products = [];

      // Check each product to see if it uses the ingredient
      productsSnapshot.forEach((doc) => {
        const product = doc.data();
        if (product.ingredients.some(ingredient => ingredient.ingredientId === ingredientId)) {
          products.push({ id: doc.id, ...product });
        }
      });

      // Recalculate and update the cost for each affected product
      for (const product of products) {
        await Product.updateProduct(product.id, {
          ingredients: product.ingredients // This is to trigger the cost recalculation
        });
      }

      console.log(`Updated products using ingredient ${ingredientId}`);
    } catch (error) {
      console.error("Error updating products by ingredient:", error.message);
      throw new Error(error.message);
    }
  }

  static async updateProduct(id, updatedProduct) {
    try {
      const productRef = doc(db, "products", id);
      await updateDoc(productRef, { ...updatedProduct });
      console.log("Product updated successfully:", updatedProduct);
    } catch (error) {
      console.error("Failed to update product:", error.message);
      throw error;
    }
  }

  // Get all products for a specific restaurant
  static async getAllProducts(restaurantId) {
    try {
      const productsSnapshot = await getDocs(collection(db, "products"));
      const products = [];
      productsSnapshot.forEach((doc) => {
        const product = doc.data();
        if (product.restaurantId === restaurantId) {
          products.push({ id: doc.id, ...product });
        }
      });
      return products;
    } catch (error) {
      console.error("Error retrieving products:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete a product
  static async deleteProduct(productId) {
    try {
      const productRef = doc(db, "products", productId);
      await deleteDoc(productRef);
      console.log("Product deleted successfully!");
    } catch (error) {
      console.error("Error deleting product:", error.message);
      throw new Error(error.message);
    }
  }
}

export default Product;
