import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import EncaissementService from "../Services/Encaisement";  // Encaissement service
import DecaissementService from "../Services/Decaisement";  // Decaissement service
import { Card, CardContent, Typography, TextField, Button } from "@mui/material";
import ExcelTable from "../Components/TableExcel/VaultTable"; // Fixed path if needed
import ExcelTable4 from "../Components/TableExcel/TableExcel";

const VaultPage = () => {
  // States for Encaissements and Decaissements
  const [encaissements, setEncaissements] = useState([]);
  const [decaissements, setDecaissements] = useState([]);
  const restaurantId = localStorage.getItem("restaurantId");

  // Fetch Data and Filter by Last Two Days
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch encaissements and decaissements data
        const fetchedEncaissements = await EncaissementService.getAllEncaissements();
        const fetchedDecaissements = await DecaissementService.getAllDecaissements();

        // Get the current date and calculate two days ago
        const today = new Date();
        const twoDaysAgo = new Date(today);
        twoDaysAgo.setDate(today.getDate() - 2);

        // Filter the data to include only entries from the last two days
        const filteredEncaissements = fetchedEncaissements.filter((item) => {
          const itemDate = new Date(item.date); // Replace 'date' with your actual date field
          return itemDate >= twoDaysAgo;
        });

        const filteredDecaissements = fetchedDecaissements.filter((item) => {
          const itemDate = new Date(item.date); // Replace 'date' with your actual date field
          return itemDate >= twoDaysAgo;
        });

        // Set the filtered data
        setEncaissements(filteredEncaissements);
        setDecaissements(filteredDecaissements);
      } catch (error) {
        console.error("Error fetching encaissements or decaissements:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>Vault Page</h1>

      {/* Vault Table with Merged Encaissements and Decaissements */}
      <h2>Vault Table</h2>
      <ExcelTable
  data={[
    ...encaissements
      .filter(row => row.restaurantId === restaurantId)
      .map(row => ({ ...row, category: 'ENCAISSEMENT' })),
    ...decaissements
      .filter(row => row.restaurantId === restaurantId)
      .map(row => ({ ...row, category: 'DECAISSEMENT' }))
  ]}
/>


      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Link to="/AllVault" style={{ textDecoration: "none" }}>
          <Button variant="outlined" color="primary">
            Voir Plus
          </Button>
        </Link>
      </div>

      {/* Encaissements Table */}
      <h2>Encaissements Table</h2>
      <ExcelTable4 
  data={encaissements.filter(encaissement => encaissement.restaurantId === restaurantId)} 
  title="ENCAISSEMENT" 
/>


      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Link to="/Encaissement" style={{ textDecoration: "none" }}>
          <Button variant="outlined" color="primary">
            Voir Plus
          </Button>
        </Link>
      </div>

      {/* Decaissements Table */}
      <h2>Decaissements Table</h2>
      <ExcelTable4 
  data={decaissements.filter( decaissement => decaissement.restaurantId === restaurantId)} 
  title="DECAISSEMENT" 
/>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Link to="/Decaissement" style={{ textDecoration: "none" }}>
          <Button variant="outlined" color="primary">
            Voir Plus
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default VaultPage;
