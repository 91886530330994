import React, { useState, useEffect } from "react";
import ExcelTable4 from "../Components/TableExcel/TableExcel";
import Encaissement from "../Services/Encaisement";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const EncaissementsPage = () => {
  const [encaissements, setEncaissements] = useState([]);
  const [newEncaissement, setNewEncaissement] = useState({
    value: "",
    type: "",
    date: new Date().toISOString().split("T")[0], // Set default date as today's date
  });
  const [editingEncaissement, setEditingEncaissement] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [existingTypes, setExistingTypes] = useState([]);
  const [newType, setNewType] = useState(""); // Added state for the new type
  const restaurantId = localStorage.getItem("restaurantId"); // Fetching restaurantId from localStorage

  // Fetch all encaissements based on restaurantId
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedEncaissements = await Encaissement.getEncaissementsByRestaurantId(restaurantId);
        setEncaissements(fetchedEncaissements);
      } catch (error) {
        console.error("Error fetching encaissements:", error.message);
      }
    };
    if (restaurantId) {
      fetchData();
    }
  }, [restaurantId]);

  // Handle adding new encaissement
  const handleAddEncaissement = async () => {
    if (!newEncaissement.value || !newEncaissement.type || !newEncaissement.date) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const typeToUse = newEncaissement.type === "newType" ? newType : newEncaissement.type;
      const encaissement = new Encaissement(
        parseFloat(newEncaissement.value),
        typeToUse,
        newEncaissement.date,
        restaurantId // Pass restaurantId to the encaissement object
      );
      await encaissement.addEncaissement();
      setEncaissements([...encaissements, encaissement]);

      // Reset input fields
      setNewEncaissement({
        value: "",
        type: "",
        date: new Date().toISOString().split("T")[0],
      });
      setNewType(""); // Reset newType
      setOpenDialog(false);
    } catch (error) {
      console.error("Error adding encaissement:", error.message);
    }
  };

  // Handle opening the edit dialog
  const handleEditEncaissement = (encaissement) => {
    setEditingEncaissement(encaissement); // Set the encaissement to edit
    setNewEncaissement({
      value: encaissement.value,
      type: encaissement.type,
      date: encaissement.date,
    });
    setOpenDialog(true);
  };

  // Handle saving the edited encaissement
  const handleSaveEdit = async () => {
    try {
      const updates = {
        value: newEncaissement.value,
        type: newEncaissement.type,
        date: newEncaissement.date,
      };

      // Make sure editingEncaissement.id is set when editing
      await Encaissement.updateEncaissement(editingEncaissement.id, updates);

      // Update state with the new encaissement data
      setEncaissements((prev) =>
        prev.map((enc) =>
          enc.id === editingEncaissement.id ? { ...enc, ...updates } : enc
        )
      );

      // Clear editing state and close dialog
      setEditingEncaissement(null);
      setNewEncaissement({ value: "", type: "", date: new Date().toISOString().split("T")[0] });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving edit:", error.message);
    }
  };

  // Handle deleting an encaissement
  const handleDeleteEncaissement = async (id) => {
    try {
      await Encaissement.deleteEncaissement(id);
      setEncaissements((prev) =>
        prev.filter((encaissement) => encaissement.id !== id)
      );
    } catch (error) {
      console.error("Error deleting encaissement:", error.message);
    }
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <h1>Encaissements</h1>

      <h2>Tableau des Encaissements</h2>
      <ExcelTable4 data={encaissements} title="ENCAISSEMENT" />

      <Card sx={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h6">Ajouter ou modifier un Encaissement</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)} // Open dialog for adding
          >
            Ajouter un Encaissement
          </Button>
        </CardContent>
      </Card>

      <h2>Liste des Encaissements</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {encaissements
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((encaissement) => (
                <TableRow key={encaissement.id} hover>
                  <TableCell>{encaissement.date}</TableCell>
                  <TableCell>{encaissement.value}</TableCell>
                  <TableCell>{encaissement.type}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditEncaissement(encaissement)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteEncaissement(encaissement.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={encaissements.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog for Add/Edit Encaissement */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{editingEncaissement ? "Edit Encaissement" : "Add Encaissement"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Value"
            type="number"
            value={newEncaissement.value}
            onChange={(e) => {
              // Parse the value to a float number
              const parsedValue = parseFloat(e.target.value);
              // Set the new value only if it's a valid number
              setNewEncaissement({ ...newEncaissement, value: isNaN(parsedValue) ? "" : parsedValue });
            }}
            fullWidth
            sx={{ marginBottom: "10px" }}
          />

<FormControl fullWidth sx={{ marginBottom: "10px" }}>
  <InputLabel>Type</InputLabel>
  <Select
    value={newEncaissement.type}
    onChange={(e) => {
      const selectedType = e.target.value;
      setNewEncaissement({
        ...newEncaissement,
        type: selectedType === "" ? "" : selectedType,
      });
    }}
  >
    {/* Extract unique types using a Set to avoid duplicates */}
    {[...new Set(encaissements.map((encaissement) => encaissement.type))]
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((uniqueType) => (
        <MenuItem key={uniqueType} value={uniqueType}>
          {uniqueType}
        </MenuItem>
      ))}
    <MenuItem value="newType">Ajouter un nouveau type</MenuItem>
  </Select>
  {newEncaissement.type === "newType" && (
    <TextField
      label="New Type"
      variant="outlined"
      fullWidth
      value={newType}
      onChange={(e) => setNewType(e.target.value)}
      sx={{ marginTop: "10px" }}
    />
  )}
</FormControl>

          <TextField
            label="Date"
            type="date"
            value={newEncaissement.date}
            onChange={(e) =>
              setNewEncaissement({ ...newEncaissement, date: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            sx={{ marginBottom: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={editingEncaissement ? handleSaveEdit : handleAddEncaissement}
            color="primary"
          >
            {editingEncaissement ? "Save Edit" : "Add Encaissement"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EncaissementsPage;
