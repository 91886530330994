import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../Firebase/Config";  // Ensure the Firebase config is correctly imported
import { getFirestore, doc, setDoc } from "firebase/firestore"; // Firestore imports
import User from "../Services/User"; // Ensure the User model is imported

const db = getFirestore(); // Get Firestore instance

class AuthController {
  static async handleSignup(email, password, profilePic, role, isVerified, payment, num, nom, prenom) {
    const user = new User(email, password, role, num, nom, prenom, isVerified, payment, profilePic);
    
    await user.signup();
    console.log("User signed up and additional details stored successfully.");
  }

  static async handleLogin(email, password) {
    const user = new User(email, password);
    await user.login();
  }

  static async handleForgotPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent.");
    } catch (error) {
      throw new Error("Error sending reset email: " + error.message);
    }
  }
}

export default AuthController;
