import { getFirestore, doc, setDoc, getDoc, deleteDoc, collection, getDocs } from "firebase/firestore";

const db = getFirestore();

class RapportX {
  constructor(restaurantId, date, value) {
    this.restaurantId = restaurantId;
    this.date = date;
    this.value = value;
  }

  // Save or update RapportX
  static async saveRapportX(restaurantId, date, value) {
    try {
      const rapportXRef = doc(db, "rapportX", `${restaurantId}_${date}`); // Document ID is a combination of restaurantId and date
      await setDoc(rapportXRef, { restaurantId, value }, { merge: true });
      console.log("RapportX saved successfully!");
    } catch (error) {
      console.error("Error saving RapportX:", error.message);
      throw new Error(error.message);
    }
  }

  // Get all RapportX by restaurantId
  static async getAllRapportX(restaurantId) {
    try {
      const rapportXSnapshot = await getDocs(collection(db, "rapportX"));
      const rapportXList = [];
      
      // Filter by restaurantId
      rapportXSnapshot.forEach((doc) => {
        if (doc.id.startsWith(restaurantId)) { // Check if document belongs to the restaurant
          rapportXList.push({ restaurantId, date: doc.id.split('_')[1], ...doc.data() });
        }
      });
      return rapportXList;
    } catch (error) {
      console.error("Error retrieving RapportX data:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete RapportX by restaurantId and date
  static async deleteRapportX(restaurantId, date) {
    try {
      const rapportXRef = doc(db, "rapportX", `${restaurantId}_${date}`);
      await deleteDoc(rapportXRef);
      console.log("RapportX deleted successfully!");
    } catch (error) {
      console.error("Error deleting RapportX:", error.message);
      throw new Error(error.message);
    }
  }
}

export default RapportX;
