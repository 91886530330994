import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RH from "../Services/RH";
import ExcelSearchScreen from "./RHEXCEL";
import { Timestamp } from "firebase/firestore";

import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  Divider,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExcelTableAccompte from "../Components/TableExcel/ExcelTableAccompte";

const WorkerDetails = () => {
  const { workerId } = useParams();
  const navigate = useNavigate();
  const [worker, setWorker] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [accompteAmount, setAccompteAmount] = useState("");
  const [accompteDate, setAccompteDate] = useState("");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editedWorker, setEditedWorker] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const fetchWorkerDetails = async () => {
      try {
        const workerData = await RH.getWorker(workerId);
        setWorker(workerData);
      } catch (error) {
        console.error("Error fetching worker details:", error.message);
      }
    };
    fetchWorkerDetails();
  }, [workerId]);

  const handleSaveWorkerDetails = async () => {
    try {
      await RH.editWorker(workerId, editedWorker);
      alert("Worker details updated successfully!");
      // Re-fetch worker details to refresh the page
      const updatedWorker = await RH.getWorker(workerId);
      setWorker(updatedWorker); // Update state with the latest worker details
      handleEditDialogClose(); // Close the edit dialog
      window.location.reload();
    } catch (error) {
      console.error("Error updating worker details:", error.message);
      alert("Failed to update worker details. Please try again.");
    }
  };
  

  const handleEditDialogOpen = () => {
    setEditedWorker({ ...worker }); // Initialize with current worker details
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleDialogOpen = () => setIsDialogOpen(true);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setAccompteAmount("");
    setAccompteDate("");
  };

  const handleAddAccompte = async () => {
    if (!accompteAmount || isNaN(accompteAmount) || parseFloat(accompteAmount) <= 0) {
      alert("Please enter a valid accompte amount.");
      return;
    }

    if (!accompteDate) {
      alert("Please select a valid date.");
      return;
    }

    try {
      const formattedDate = new Date(accompteDate);
      if (isNaN(formattedDate.getTime())) {
        alert("Invalid date format.");
        return;
      }

      await RH.addAccompte(workerId, parseFloat(accompteAmount), formattedDate);
      alert("Accompte added successfully!");
      handleDialogClose();
      const updatedWorker = await RH.getWorker(workerId);
      setWorker(updatedWorker);
    } catch (error) {
      console.error("Error adding accompte:", error.message);
      alert("Failed to add accompte. Please try again.");
    }
  };

  const handleDeleteAccompte = async (accompteId) => {
    if (!window.confirm("Are you sure you want to delete this accompte?")) {
      return;
    }

    try {
      await RH.deleteAccompte(workerId, accompteId);
      alert("Accompte deleted successfully!");
      const updatedWorker = await RH.getWorker(workerId);
      setWorker(updatedWorker);
    } catch (error) {
      console.error("Error deleting accompte:", error.message);
      alert("Failed to delete accompte. Please try again.");
    }
  };

  const handleDeleteWorker = async () => {
    if (window.confirm("Are you sure you want to delete this worker?")) {
      try {
        await RH.deleteWorker(workerId);
        alert("Worker deleted successfully!");
        navigate("/workers"); // Redirect to the worker list or another page
      } catch (error) {
        console.error("Error deleting worker:", error.message);
        alert("Failed to delete worker. Please try again.");
      }
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      return new Intl.DateTimeFormat("fr-FR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      }).format(date);
    }
    return "Invalid Date";
  };

  if (!worker) {
    return (
      <Container>
        <Typography variant="h5" align="center" sx={{ mt: 4 }}>
          Loading Worker Details...
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <ExcelSearchScreen worker={worker} selectedWorkerId={worker.id} />

      <Card sx={{ mb: 4, boxShadow: 4, borderRadius: 3 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <AccountCircleIcon color="primary" sx={{ fontSize: 80 }} />
            </Grid>
            <Grid item xs>
              <Typography variant="h4" display="flex" alignItems="center">
                {worker.name} {worker.prenom}
                <Tooltip title="Edit Worker">
                  <IconButton
                    color="primary"
                    onClick={handleEditDialogOpen}
                    sx={{ ml: 2 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
             
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Role: {worker.role}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Salary: {worker.salary} TND
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Status: {worker.statue}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                CIN: {worker.cin}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {worker.accompte && worker.accompte.length > 0 && (
        <Card sx={{ mb: 4, boxShadow: 2, borderRadius: 3 }}>
          <CardContent>
            <Typography variant="h6">Accomptes</Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              {worker.accompte.map((accompte, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${accompte.amount} TND`}
                    secondary={`Le ${formatDate(accompte.date)}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      color="error"
                      onClick={() => handleDeleteAccompte(accompte.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}

      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={handleDialogOpen}
        sx={{ mb: 2 }}
      >
        Add Accompte
      </Button>

      <ExcelTableAccompte workerData={worker} />

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Add Accompte</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              label="Accompte Amount"
              type="number"
              fullWidth
              value={accompteAmount}
              onChange={(e) => setAccompteAmount(e.target.value)}
            />
            <TextField
              label="Accompte Date"
              type="date"
              fullWidth
              value={accompteDate}
              onChange={(e) => setAccompteDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleAddAccompte} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Worker Dialog */}
      <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Worker</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Name"
              fullWidth
              value={editedWorker.name || ""}
              onChange={(e) => setEditedWorker({ ...editedWorker, name: e.target.value })}
            />
            <TextField
              label="Prenom"
              fullWidth
              value={editedWorker.prenom || ""}
              onChange={(e) => setEditedWorker({ ...editedWorker, prenom: e.target.value })}
            />
           <FormControl fullWidth>
  <InputLabel>Role</InputLabel>
  <Select
    label="Role"
    value={editedWorker.role || ""}
    onChange={(e) => setEditedWorker({ ...editedWorker, role: e.target.value })}
  >
    
         <MenuItem value="Gérant">Gérant</MenuItem>
         <MenuItem value="Chef de cuisine">Chef de cuisine</MenuItem>
         <MenuItem value="Cuisinier">Cuisinier</MenuItem>
         <MenuItem value="Aide-cuisinier">Aide-cuisinier</MenuItem>
         <MenuItem value="Serveur">Serveur/Serveuse</MenuItem>
         <MenuItem value="Barman">Barman</MenuItem>
         <MenuItem value="Barmaid">Barmaid</MenuItem>
         <MenuItem value="Plongeur">Plongeur</MenuItem>
         <MenuItem value="Hôte">Hôte</MenuItem>
         <MenuItem value="Hôtesse d'accueil">Hôtesse d'accueil</MenuItem>
         <MenuItem value="Responsable de salle">Responsable de salle</MenuItem>
    {/* Add more roles as needed */}
  </Select>
</FormControl>
            <TextField
              label="Salary"
              fullWidth
              value={editedWorker.salary || ""}
              onChange={(e) => setEditedWorker({ ...editedWorker, salary: e.target.value })}
            />
            <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Statue</InputLabel>
                            <Select
                              label="Statue"
                              value={editedWorker.statue}
                              onChange={(e) => setEditedWorker({ ...editedWorker, statue: e.target.value })}
                            >
                              {["Celibataire", "Marie", "M+1", "M+2", "M+3", "M+4"].map((status) => (
                                <MenuItem key={status} value={status}>
                                  {status}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
           
            <TextField
              label="CIN"
              fullWidth
              value={editedWorker.cin || ""}
              onChange={(e) => setEditedWorker({ ...editedWorker, cin: e.target.value })}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleSaveWorkerDetails} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Worker Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this worker?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleDeleteWorker} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default WorkerDetails;
