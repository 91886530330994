import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Box, Typography, Grid, Paper } from "@mui/material";
import Encaissement from "../Services/Encaisement";
import Decaissement from "../Services/Decaisement";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const AnalyticsPage = () => {
  const restaurantId = localStorage.getItem('restaurantId');

  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({ totalEncaissement: 0, totalDecaissement: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const encaissements = await Encaissement.getAllEncaissements();
        const decaissements = await Decaissement.getAllDecaissements();

        const combinedData = [];
        const allDates = [
          ...new Set([
            ...encaissements.map((e) => e.date),
            ...decaissements.map((d) => d.date),
          ]),
        ];

        let totalEncaissement = 0;
        let totalDecaissement = 0;

        allDates.forEach((date) => {
          const encaissementValue =
            encaissements.find((e) => e.date === date)?.value || 0;
          const decaissementValue =
            decaissements.find((d) => d.date === date)?.value || 0;

          totalEncaissement += encaissementValue;
          totalDecaissement += decaissementValue;

          combinedData.push({
            date,
            encaissement: encaissementValue,
            decaissement: decaissementValue,
          });
        });

        setData(combinedData);
        setSummary({ totalEncaissement, totalDecaissement });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching analytics data:", error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Typography>Loading data...</Typography>;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Analytics Dashboard
      </Typography>
      
      <Grid container spacing={4}>
        {/* Summary Cards */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 3, textAlign: "center" }}>
            <Typography variant="h6">Total Encaissement</Typography>
            <Typography variant="h4" color="primary">
              {summary.totalEncaissement} DT
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 3, textAlign: "center" }}>
            <Typography variant="h6">Total Décaissement</Typography>
            <Typography variant="h4" color="secondary">
              {summary.totalDecaissement} DT
            </Typography>
          </Paper>
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Encaissement vs Décaissement (Bar Chart)
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="encaissement" fill="#82ca9d" />
                <Bar dataKey="decaissement" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Line Chart */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Encaissement Trend (Line Chart)
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="encaissement" stroke="#82ca9d" />
                <Line type="monotone" dataKey="decaissement" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Pie Chart */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Distribution of Encaissement and Décaissement
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={[
                    { name: "Encaissement", value: summary.totalEncaissement },
                    { name: "Décaissement", value: summary.totalDecaissement },
                  ]}
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  label
                  dataKey="value"
                >
                  {COLORS.map((color, index) => (
                    <Cell key={`cell-${index}`} fill={color} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticsPage;
