import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import EncaissementService from "../Services/Encaisement"; // Encaissement service
import DecaissementService from "../Services/Decaisement"; // Decaissement service
import { Button } from "@mui/material";
import ExcelTable from "../Components/TableExcel/VaultTable"; // Fixed path if needed

const CaisseTransactions = () => {
  // States for Encaissements and Decaissements
  const [encaissements, setEncaissements] = useState([]);
  const [decaissements, setDecaissements] = useState([]);

  // Fetch Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch encaissements and decaissements data
        const fetchedEncaissements = await EncaissementService.getAllEncaissements();
        const fetchedDecaissements = await DecaissementService.getAllDecaissements();

        // Set the data without filtering
        setEncaissements(fetchedEncaissements);
        setDecaissements(fetchedDecaissements);
      } catch (error) {
        console.error("Error fetching encaissements or decaissements:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>Vault Page</h1>

      {/* Vault Table with Merged Encaissements and Decaissements */}
      <h2>Vault Table</h2>
      <ExcelTable
  data={[
    ...encaissements.map(row => ({ ...row, category: 'ENCAISSEMENT' })),
    ...decaissements.map(row => ({ ...row, category: 'DECAISSEMENT' }))
  ]}
/>
    
    </div>
  );
};
 
export default CaisseTransactions;
