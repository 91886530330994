import './ExcelTable.css';

const ExcelTableAccompte = ({ workerData }) => {
  // Extract the `accompte` array from workerData
  const data = Array.isArray(workerData?.accompte) ? workerData.accompte : [];

  // Format the date for better readability
  const formatDate = (date) => {
    if (!date) return "Date Inconnue";
    const parsedDate = new Date(date.seconds * 1000); // Assuming date is a Firestore Timestamp
    return parsedDate.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  };

  return (
    <div >
      <table className="excel-table">
        <thead>
          <tr>
            <th className="header-cell">Date Acompte</th>
            <th className="header-cell">Montant Acompte</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((accompte, index) => (
              <tr key={index} className="data-row">
                <td>{formatDate(accompte.date)}</td> {/* Format and display the date */}
                <td>{accompte.amount || "Montant Inconnu"} TND</td> {/* Display the amount */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="no-data">
                Aucune donnée d'acompte disponible
              </td>
            </tr>
          )}
        </tbody>
      </table>
   
    </div>
  );
};

export default ExcelTableAccompte;
