import { 
  getFirestore, 
  doc, 
  setDoc, 
  getDoc, 
  updateDoc, 
  deleteDoc, 
  collection, 
  getDocs, 
  query, 
  where 
} from "firebase/firestore";

const db = getFirestore();

class Encaissement {
  constructor(value, type, date, restaurantId) {
    this.value = value;
    this.type = type;
    this.date = date;
    this.restaurantId = restaurantId;
    this.id = null; // Will be set after adding the encaissement
  }

  // Add a new encaissement
  async addEncaissement() {
    try {
      const encaissementRef = doc(collection(db, "encaissements"));

      await setDoc(encaissementRef, {
        value: this.value,
        type: this.type,
        date: this.date,
        restaurantId: this.restaurantId,
      });

      this.id = encaissementRef.id; // Set the ID after document is added
      console.log("Encaissement added successfully!");
    } catch (error) {
      console.error("Error adding encaissement:", error.message);
      throw new Error(error.message);
    }
  }

  // Update an existing encaissement
  static async updateEncaissement(encaissementId, updates) {
    try {
      const encaissementRef = doc(db, "encaissements", encaissementId);
      const encaissementSnap = await getDoc(encaissementRef);

      if (!encaissementSnap.exists()) {
        throw new Error("Encaissement not found.");
      }

      await updateDoc(encaissementRef, updates);
      console.log("Encaissement updated successfully!");
    } catch (error) {
      console.error("Error updating encaissement:", error.message);
      throw new Error(error.message);
    }
  }

  // Get all encaissements
  static async getAllEncaissements() {
    try {
      const encaissementsSnapshot = await getDocs(collection(db, "encaissements"));
      const encaissements = [];
      encaissementsSnapshot.forEach((doc) => {
        encaissements.push({ id: doc.id, ...doc.data() });
      });
      return encaissements;
    } catch (error) {
      console.error("Error retrieving encaissements:", error.message);
      throw new Error(error.message);
    }
  }

  // Get encaissements by restaurant ID
  static async getEncaissementsByRestaurantId(restaurantId) {
    try {
      const encaissementsQuery = query(
        collection(db, "encaissements"),
        where("restaurantId", "==", restaurantId)
      );
      const encaissementsSnapshot = await getDocs(encaissementsQuery);
      const encaissements = [];
      encaissementsSnapshot.forEach((doc) => {
        encaissements.push({ id: doc.id, ...doc.data() });
      });
      return encaissements;
    } catch (error) {
      console.error("Error retrieving encaissements by restaurant ID:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete an encaissement
  static async deleteEncaissement(encaissementId) {
    try {
      const encaissementRef = doc(db, "encaissements", encaissementId);
      await deleteDoc(encaissementRef);
      console.log("Encaissement deleted successfully!");
    } catch (error) {
      console.error("Error deleting encaissement:", error.message);
      throw new Error(error.message);
    }
  }
}

export default Encaissement;
