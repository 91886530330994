import React, { useState, useEffect } from "react";
import Decaissement from "../Services/Decaisement"; // Service for API calls
import ExcelTable4 from "../Components/TableExcel/TableExcel"; // Export to Excel Component
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const DecaissementsPage = () => {
  const [decaissements, setDecaissements] = useState([]);
  const [newDecaissement, setNewDecaissement] = useState({
    value: "",
    type: "",
    date: new Date().toISOString().split("T")[0],
  });
  const [editingDecaissement, setEditingDecaissement] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [existingTypes, setExistingTypes] = useState([]);
  const [newType, setNewType] = useState("");
  const restaurantId = localStorage.getItem("restaurantId");

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch decaissements by restaurant ID
        const fetchedDecaissements = await Decaissement.getDecaissementsByRestaurantId(restaurantId);
        setDecaissements(fetchedDecaissements);

        // Fetch existing types
        const fetchedTypes = await Decaissement.getExistingTypes();
        setExistingTypes(fetchedTypes);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [restaurantId]);

  const handleAddDecaissement = async () => {
    if (!newDecaissement.value || !newDecaissement.type || !newDecaissement.date) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const typeToUse = newDecaissement.type === "newType" ? newType : newDecaissement.type;
      const decaissement = new Decaissement(
        parseFloat(newDecaissement.value),
        typeToUse,
        newDecaissement.date,
        restaurantId
      );
      await decaissement.addDecaissement();
      setDecaissements([...decaissements, decaissement]);

      setNewDecaissement({
        value: "",
        type: "",
        date: new Date().toISOString().split("T")[0],
      });
      setNewType("");
      setOpenDialog(false);
    } catch (error) {
      console.error("Error adding decaissement:", error.message);
    }
  };

  const handleEditDecaissement = (decaissement) => {
    setEditingDecaissement(decaissement);
    setNewDecaissement({
      value: decaissement.value,
      type: decaissement.type,
      date: decaissement.date,
    });
    setOpenDialog(true);
  };

  const handleSaveEdit = async () => {
    if (!newDecaissement.value || !newDecaissement.type || !newDecaissement.date) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const updates = {
        value: newDecaissement.value,
        type: newDecaissement.type,
        date: newDecaissement.date,
        restaurantId,
      };

      await Decaissement.updateDecaissement(editingDecaissement.id, updates);

      setDecaissements((prev) =>
        prev.map((dec) =>
          dec.id === editingDecaissement.id ? { ...dec, ...updates } : dec
        )
      );

      setEditingDecaissement(null);
      setNewDecaissement({
        value: "",
        type: "",
        date: new Date().toISOString().split("T")[0],
      });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving edit:", error.message);
    }
  };

  const handleDeleteDecaissement = async (id) => {
    try {
      await Decaissement.deleteDecaissement(id);
      setDecaissements((prev) =>
        prev.filter((decaissement) => decaissement.id !== id)
      );
    } catch (error) {
      console.error("Error deleting decaissement:", error.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <h1>Decaissements</h1>
      <ExcelTable4 data={decaissements} title="DECAISSEMENT" />

      <Card sx={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h6">Ajouter ou modifier un Décaissement</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Ajouter un Décaissement
          </Button>
        </CardContent>
      </Card>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Valeur</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {decaissements
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((decaissement) => (
                <TableRow key={decaissement.id}>
                  <TableCell>{decaissement.date}</TableCell>
                  <TableCell>{decaissement.value}</TableCell>
                  <TableCell>{decaissement.type}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditDecaissement(decaissement)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteDecaissement(decaissement.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={decaissements.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {editingDecaissement ? "Edit Decaissement" : "Add Decaissement"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Valeur"
            type="number"
            value={newDecaissement.value}
            onChange={(e) =>{
              const parsedValue = parseFloat(e.target.value);
              setNewDecaissement({ ...newDecaissement, value: isNaN(parsedValue) ? "" : parsedValue  })
            }}
            fullWidth
            sx={{ marginBottom: "10px" }}
          />
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <InputLabel>Type</InputLabel>
            <Select
              value={newDecaissement.type}
              onChange={(e) => {
                const selectedType = e.target.value;
                setNewDecaissement({
                  ...newDecaissement,
                  type: selectedType === "" ? "" : selectedType,
                });
              }}
            >
              {/* Extract unique types from decaissements to avoid duplicates */}
              {[...new Set(decaissements.map((decaissement) => decaissement.type))]
                .map((uniqueType) => (
                  <MenuItem key={uniqueType} value={uniqueType}>
                    {uniqueType}
                  </MenuItem>
                ))}
              <MenuItem value="newType">Ajouter un nouveau type</MenuItem>
            </Select>
            {newDecaissement.type === "newType" && (
              <TextField
                label="New Type"
                variant="outlined"
                fullWidth
                value={newType}
                onChange={(e) => setNewType(e.target.value)}
                sx={{ marginTop: "10px" }}
              />
            )}
          </FormControl>
          <TextField
            label="Date"
            type="date"
            value={newDecaissement.date}
            onChange={(e) =>
              setNewDecaissement({ ...newDecaissement, date: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            sx={{ marginBottom: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Annuler
          </Button>
          <Button
            onClick={editingDecaissement ? handleSaveEdit : handleAddDecaissement}
            color="primary"
          >
            {editingDecaissement ? "Enregistrer" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DecaissementsPage;
