import React, { useState, useEffect } from "react";
import RapportX from "../../Services/RapportX"; // Import the RapportX class
import "./VaultExcel.css";

const ExcelTable = ({ data }) => {
  const [rapportXValues, setRapportXValues] = useState({});
  const restaurantId = localStorage.getItem("restaurantId"); // Get restaurantId from localStorage

  useEffect(() => {
    const fetchRapportX = async () => {
      try {
        const allRapportX = await RapportX.getAllRapportX(restaurantId);
        const mappedValues = allRapportX.reduce((acc, item) => {
          acc[item.date] = item.value;
          return acc;
        }, {});
        setRapportXValues(mappedValues);
      } catch (error) {
        console.error("Error fetching RapportX:", error.message);
      }
    };

    fetchRapportX();
  }, [restaurantId]);

  const handleInputChange = async (date, value) => {
    setRapportXValues((prev) => ({
      ...prev,
      [date]: value,
    }));

    try {
      await RapportX.saveRapportX(restaurantId, date, value);
    } catch (error) {
      console.error("Error saving RapportX:", error.message);
    }
  };

  // If no data, show a message
  if (!data || data.length === 0) {
    return (
      <div className="table-container">
        <p>No data available</p>
      </div>
    );
  }

  // Filter data by restaurantId
  const filteredData = data.filter((row) => row.restaurantId === restaurantId);

  // Separate types for encaissement and décaissement
  const encaissementTypes = [
    ...new Set(
      filteredData
        .filter((row) => row.category === "ENCAISSEMENT")
        .map((row) => row.type || "N/A")
    ),
  ];
  const decaissementTypes = [
    ...new Set(
      filteredData
        .filter((row) => row.category === "DECAISSEMENT")
        .map((row) => row.type || "N/A")
    ),
  ];

  const groupedData = filteredData.reduce((acc, row) => {
    const dateKey = row.date || "N/A";
    if (!acc[dateKey]) {
      acc[dateKey] = { encaissement: {}, decaissement: {}, date: dateKey };
    }
    const category = row.category === "ENCAISSEMENT" ? "encaissement" : "decaissement";
    acc[dateKey][category][row.type] = (acc[dateKey][category][row.type] || 0) + (row.value || 0);
    return acc;
  }, {});

  const groupedDataArray = Object.values(groupedData).sort((a, b) => new Date(b.date) - new Date(a.date));

  const formatNumber = (value) => (typeof value === "number" && !isNaN(value) ? value.toFixed(3) : "0.000");

  return (
    <table className="excel-table">
      <thead>
        <tr>
          <th rowSpan="2">Date</th>
          {encaissementTypes.length > 0 && (
            <th colSpan={encaissementTypes.length}>Encaissement</th>
          )}
          {decaissementTypes.length > 0 && (
            <th colSpan={decaissementTypes.length}>Décaissement</th>
          )}
          {/* Conditionally render Recap Caisse columns */}
          {(encaissementTypes.length > 0 || decaissementTypes.length > 0) && (
            <th colSpan={3}>Recap Caisse</th>
          )}
        </tr>
        <tr>
          {encaissementTypes.length > 0 &&
            encaissementTypes.map((type) => (
              <th key={`enc-header-${type}`}>{type}</th>
            ))}
          {decaissementTypes.length > 0 &&
            decaissementTypes.map((type) => (
              <th key={`dec-header-${type}`}>{type}</th>
            ))}
          {/* Conditionally render these columns based on Encaissement or Decaissement */}
          {(encaissementTypes.length > 0 || decaissementTypes.length > 0) && (
            <>
              <th>Total Caisse</th>
              <th>Rapport X</th>
              <th>Écart</th>
            </>
          )}
        </tr>
      </thead>

      <tbody>
        {groupedDataArray.map((row, index) => {
          const totalEncaissement = Object.values(row.encaissement).reduce(
            (sum, val) => sum + val,
            0
          );
          const totalDecaissement = Object.values(row.decaissement).reduce(
            (sum, val) => sum + val,
            0
          );
          const totalCaisse = totalEncaissement + totalDecaissement;
          const rapportX = rapportXValues[row.date];
          const ecart = totalCaisse - rapportX;

          return (
            <tr key={index}>
              <td>{row.date}</td>
              {encaissementTypes.map((type) => (
                <td key={`enc-value-${type}-${index}`}>{formatNumber(row.encaissement[type])}</td>
              ))}
              {decaissementTypes.map((type) => (
                <td key={`dec-value-${type}-${index}`}>{formatNumber(row.decaissement[type])}</td>
              ))}
              {(encaissementTypes.length > 0 || decaissementTypes.length > 0) && (
                <>
                  <td>{formatNumber(totalCaisse)}</td>
                  <td>
  <input
    type="text"
    value={rapportX !== undefined ? String(rapportX) : "0"}
    onChange={(e) => {
      const inputValue = e.target.value;
      // Allow only valid numeric input, including dots
      if (/^\d*\.?\d*$/.test(inputValue)) {
        handleInputChange(row.date, inputValue); // Pass the raw string value
      }
    }}
    onBlur={(e) => {
      const inputValue = e.target.value;
      // Convert to number on blur (when user exits the field)
      handleInputChange(
        row.date,
        inputValue === "" ? 0 : parseFloat(inputValue)
      );
    }}
    placeholder="Enter value"
  />
</td>


                  <td
                    className="ecart-cell"
                    style={{
                      color: ecart >= 0 ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {formatNumber(ecart)}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExcelTable;
