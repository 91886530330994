import React, { useState, useEffect } from "react";
import RapportX from "../../Services/RapportX"; // Import the RapportX class
import "./VaultExcel.css";

const ExcelTable = ({ data }) => {
  const [rapportXValues, setRapportXValues] = useState({});
  const restaurantId = localStorage.getItem("restaurantId"); // Get restaurantId from localStorage

  useEffect(() => {
    const fetchRapportX = async () => {
      try {
        // Fetch RapportX data filtered by restaurantId
        const allRapportX = await RapportX.getAllRapportX(restaurantId);
        const mappedValues = allRapportX.reduce((acc, item) => {
          acc[item.date] = item.value;
          return acc;
        }, {});
        setRapportXValues(mappedValues);
      } catch (error) {
        console.error("Error fetching RapportX:", error.message);
      }
    };

    fetchRapportX();
  }, [restaurantId]);

  const handleInputChange = async (date, value) => {
    setRapportXValues((prev) => ({
      ...prev,
      [date]: value,
    }));

    try {
      await RapportX.saveRapportX(restaurantId, date, value); // Pass restaurantId to saveRapportX
    } catch (error) {
      console.error("Error saving RapportX:", error.message);
    }
  };

  // If no data, show a message
  if (!data || data.length === 0) {
    return (
      <div className="table-container">
        <p>No data available</p>
      </div>
    );
  }

  // Filter data by restaurantId
  const filteredData = data.filter((row) => row.restaurantId === restaurantId);

  const types = [...new Set(filteredData.map((row) => row.type || "N/A"))];

  const groupedData = filteredData.reduce((acc, row) => {
    const dateKey = row.date || "N/A";
    if (!acc[dateKey]) {
      acc[dateKey] = { encaissement: {}, decaissement: {}, date: dateKey };
    }
    const category = row.category === "ENCAISSEMENT" ? "encaissement" : "decaissement";
    acc[dateKey][category][row.type] = (acc[dateKey][category][row.type] || 0) + (row.value || 0);
    return acc;
  }, {});

  const groupedDataArray = Object.values(groupedData).sort((a, b) => new Date(b.date) - new Date(a.date));

  const formatNumber = (value) => (typeof value === "number" && !isNaN(value) ? value.toFixed(3) : "0.000");

  return (
    <div className="table-container">
      <table className="excel-table">
        <thead>
          <tr>
            <th rowSpan="2">Date</th>
            <th colSpan={types.length}>Encaissement</th>
            <th colSpan={types.length}>Décaissement</th>
            <th colSpan={3}>Recap Caisse</th>
          </tr>
          <tr>
            {types.map((type) => (
              <th key={`enc-header-${type}`}>{type}</th>
            ))}
            {types.map((type) => (
              <th key={`dec-header-${type}`}>{type}</th>
            ))}
            <th>Total Caisse</th>
            <th>Rapport X</th>
            <th>Écart</th>
          </tr>
        </thead>
        <tbody>
          {groupedDataArray.map((row, index) => {
            const totalEncaissement = Object.values(row.encaissement).reduce((sum, val) => sum + val, 0);
            const totalDecaissement = Object.values(row.decaissement).reduce((sum, val) => sum + val, 0);
            const totalCaisse = totalEncaissement + totalDecaissement;
            const rapportX = rapportXValues[row.date];
            const ecart = totalCaisse - rapportX;

            return (
              <tr key={index}>
                <td>{row.date}</td>
                {types.map((type) => (
                  <td key={`enc-value-${type}-${index}`}>{formatNumber(row.encaissement[type])}</td>
                ))}
                {types.map((type) => (
                  <td key={`dec-value-${type}-${index}`}>{formatNumber(row.decaissement[type])}</td>
                ))}
                <td>{formatNumber(totalCaisse)}</td>
                <td>
                <input
  type="text"
  value={rapportX !== undefined ? rapportX : 0} // Default to 0
  onChange={(e) => handleInputChange(row.date, e.target.value === "" ? 0 : parseFloat(e.target.value))} // If cleared, set to 0
  placeholder="Enter value" // Optional placeholder
/>

                </td>
                <td
  className="ecart-cell"
  style={{
    color: ecart >= 0 ? 'green' : 'red',
    fontWeight: 'bold',
  }}
>
  {formatNumber(ecart)}
</td>

              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelTable;
