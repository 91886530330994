import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../Firebase/Config"; 
import Restaurant from "../Services/Restaurant"; 
import {
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,Text
} from "@mui/material";
import { useNavigate } from "react-router-dom"; 
import { TextFormat } from "@mui/icons-material";

const AddRestaurantFormPopup = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [phone, setPhone] = useState("");
  const [matriculeFiscale, setMatriculeFiscale] = useState("");
  const [RestoPic, setRestoPic] = useState(null);
  const [RestoPicPreview, setRestoPicPreview] = useState(null); 
  const [locals, setLocals] = useState([]);
  const [message, setMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(1); 
  const [localInput, setLocalInput] = useState(""); 
  const [open, setOpen] = useState(false); 
  const navigate = useNavigate(); 

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddRestaurant = async (e) => {
    e.preventDefault();
    
    if (!currentUser) {
      setMessage("User not authenticated.");
      return;
    }

    const newRestaurant = new Restaurant(name, address, phone, currentUser.uid, matriculeFiscale, RestoPic, locals);
    
    try {
      await newRestaurant.addRestaurant();
      setMessage("Restaurant added successfully!");
      setCurrentStep(3); 
      await newRestaurant.updateUserVerification(currentUser.uid);
      navigate("/Home"); 
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setRestoPic(file);

    if (file) {
      setRestoPicPreview(URL.createObjectURL(file)); 
    }
  };

  const handleAddLocal = () => {
    if (localInput) {
      setLocals([...locals, localInput]);
      setLocalInput(""); 
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <TextField
              label="Restaurant Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
           
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
          
            <TextField
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
           
            <TextField
              label="Matricule Fiscale"
              value={matriculeFiscale}
              onChange={(e) => setMatriculeFiscale(e.target.value)}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCurrentStep(2)}
              sx={{ mt: 2 }}
            >
              Next
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Upload Restaurant Picture and Add Local
            </Typography>
            <TextField
              label="Enter Local Adresse"
              value={localInput}
              onChange={(e) => setLocalInput(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button
              variant="outlined"
              onClick={handleAddLocal}
              sx={{ mb: 2 }}
            >
              Add Local
            </Button>
            <List>
              {locals.map((local, index) => (
                <ListItem key={index}>
                  <ListItemText primary={local} />
                </ListItem>
              ))}
            </List>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddRestaurant}
              sx={{ mt: 2 }}
            >
              Add Restaurant
            </Button>
          </>
        );
      case 3:
        return (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" color="success.main" gutterBottom>
              Restaurant Added Successfully!
            </Typography>
           
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Restaurant
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Add a Restaurant</DialogTitle>
        <DialogContent dividers>
          {renderStepContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddRestaurantFormPopup;
