import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import Ingredient from "../Services/Ingredient"; // Assuming Ingredient is imported from your service

const IngredientsPage = () => {
  const [ingredients, setIngredients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentIngredient, setCurrentIngredient] = useState(null);
  const restaurantId = localStorage.getItem("restaurantId"); // Assuming the restaurant ID is stored in localStorage
  const [form, setForm] = useState({
    name: '',
    pricePerUnit: '',
    unit: 'kg', // Default unit
  });

  // List of available units
  const units = ['kg', 'g', 'L', 'mL', 'pieces', 'box', 'can'];

  // Fetch ingredients by restaurant ID
  const fetchIngredients = useCallback(async () => {
    if (!restaurantId) {
      console.error("Restaurant ID is not available.");
      return;
    }

    try {
      const fetchedIngredients = await Ingredient.getAllIngredientsByRestaurant(restaurantId);
      setIngredients(fetchedIngredients);
    } catch (error) {
      console.error('Error fetching ingredients:', error.message);
      setIngredients([]);
    }
  }, [restaurantId]);

  useEffect(() => {
    fetchIngredients();
  }, [fetchIngredients]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleCreateOrUpdate = async () => {
    try {
      if (currentIngredient) {
        // Update existing ingredient
        await Ingredient.updateIngredient(currentIngredient.id, {
          name: form.name,
          pricePerUnit: parseFloat(form.pricePerUnit),
          unit: form.unit,
        });
      } else {
        // Create new ingredient
        const newIngredient = new Ingredient(form.name, parseFloat(form.pricePerUnit), form.unit, restaurantId);
        await newIngredient.addIngredient();
      }
      fetchIngredients();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving ingredient:', error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      await Ingredient.deleteIngredient(id);
      fetchIngredients();
    } catch (error) {
      console.error('Error deleting ingredient:', error.message);
    }
  };

  const handleOpenModal = (ingredient = null) => {
    setCurrentIngredient(ingredient);
    setForm(
      ingredient || { name: '', pricePerUnit: '', unit: 'kg' }
    );
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentIngredient(null);
    setForm({ name: '', pricePerUnit: '', unit: 'kg' });
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Ingredients Management
      </Typography>

      <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
        Add Ingredient
      </Button>

      <TableContainer sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Price per Unit</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients.length > 0 ? (
              ingredients.map((ingredient, index) => (
                <TableRow key={ingredient.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{ingredient.name}</TableCell>
                  <TableCell>{ingredient.pricePerUnit}</TableCell>
                  <TableCell>{ingredient.unit}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={() => handleOpenModal(ingredient)}
                      sx={{ marginRight: 1 }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDelete(ingredient.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No ingredients available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Add/Edit */}
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="ingredient-modal-title"
        aria-describedby="ingredient-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="ingredient-modal-title" variant="h6" gutterBottom>
            {currentIngredient ? 'Edit Ingredient' : 'Add Ingredient'}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"  // Makes it box-like
            label="Name"
            name="name"
            value={form.name}
            onChange={handleInputChange}
            margin="normal"
            sx={{ borderRadius: 2 }}
          />
          <TextField
            fullWidth
            variant="outlined"  // Makes it box-like
            label="Price per Unit"
            name="pricePerUnit"
            type="number"
            value={form.pricePerUnit}
            onChange={handleInputChange}
            margin="normal"
            sx={{ borderRadius: 2 }}
          />
          {/* Unit dropdown with box-like styling */}
          <FormControl fullWidth margin="normal" variant="outlined" sx={{ borderRadius: 2 }}>
            <InputLabel>Unit</InputLabel>
            <Select
              name="unit"
              value={form.unit}
              onChange={handleInputChange}
              label="Unit"
            >
              {units.map((unit, index) => (
                <MenuItem key={index} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ marginRight: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleCreateOrUpdate}>
              {currentIngredient ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default IngredientsPage;
