import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Button, Card, CardContent, Typography, Box, Grid } from '@mui/material';

const db = getFirestore();

// Move this function outside of the component
const getEntriesByPartenaireId = async (partenaireId) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'MontantDateStatue'));
    const entries = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.PartenaireId === partenaireId) {
        entries.push({ id: doc.id, ...data });
      }
    });

    return entries;
  } catch (error) {
    console.error('Error retrieving MontantDateStatue entries by partenaireId:', error.message);
    throw new Error('Error retrieving MontantDateStatue entries by partenaireId: ' + error.message);
  }
};

const MontantDateStatueList = ({ partenaireId }) => { // Expecting 'partenaireId' prop
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const fetchedEntries = await getEntriesByPartenaireId(partenaireId);
        setEntries(fetchedEntries);
      } catch (err) {
        setError('Error fetching data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    if (partenaireId) { // Check if partenaireId exists
      fetchEntries();
    }
  }, [partenaireId]); // Re-run when partenaireId changes

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'MontantDateStatue', id));
      setEntries(entries.filter(entry => entry.id !== id));
      alert('Entry deleted successfully!');
    } catch (err) {
      alert('Error deleting entry: ' + err.message);
    }
  };

  const handleEdit = async (id) => {
    const entryToEdit = entries.find(entry => entry.id === id);
    if (entryToEdit) {
      const newDateDebut = prompt('Enter new DateDebut', entryToEdit.DateDebut);
      const newDateFin = prompt('Enter new DateFin', entryToEdit.DateFin);
      const newMontantRecu = prompt('Enter new MontantRecu', entryToEdit.MontantRecu);
      const newStatue = prompt('Enter new Statue', entryToEdit.Statue);
      const newEcart = prompt('Enter new Ecart', entryToEdit.Ecart);

      const updatedEntry = {
        DateDebut: newDateDebut,
        DateFin: newDateFin,
        MontantRecu: newMontantRecu,
        Statue: newStatue,
        Ecart: newEcart,
      };

      try {
        await updateDoc(doc(db, 'MontantDateStatue', id), updatedEntry);
        setEntries(entries.map(entry => (entry.id === id ? { ...entry, ...updatedEntry } : entry)));
        alert('Entry updated successfully!');
      } catch (err) {
        alert('Error updating entry: ' + err.message);
      }
    }
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        MontantDateStatue Entries
      </Typography>
     

      <Grid container spacing={2}>
        {entries.map((entry) => (
          <Grid item xs={12} sm={6} md={4} key={entry.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  <strong>Date Debut:</strong> {entry.DateDebut}
                </Typography>
                <Typography variant="body1">
                  <strong>Date Fin:</strong> {entry.DateFin}
                </Typography>
                <Typography variant="body1">
                  <strong>Montant Recu:</strong> {entry.MontantRecu}
                </Typography>
                <Typography variant="body1">
                  <strong>PID:</strong> {entry.partenaireId}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Ecart:</strong> {entry.Ecart}
                </Typography>
                <Box display="flex" justifyContent="space-between">
                
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(entry.id)}
                  >
                    Delete
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MontantDateStatueList;
