import React, { useState } from "react";
import { doc, setDoc, collection, getFirestore } from "firebase/firestore";
import "./ExcelTable.css";

const db = getFirestore();

const ExcelTablePartenaire = ({ partenairesData, encaissement }) => {
  const [searchDateDebut, setSearchDateDebut] = useState("");
  const [searchDateFin, setSearchDateFin] = useState("");
  const [montantRecu, setMontantRecu] = useState(0);

  // Group encaissements by date
  const groupEncaissementsByDate = (encaissements) =>
    encaissements.reduce((acc, encaissement) => {
      if (!acc[encaissement.date]) acc[encaissement.date] = [];
      acc[encaissement.date].push(encaissement);
      return acc;
    }, {});

  const encaissementsGroupedByDate = groupEncaissementsByDate(encaissement);

  // Filter encaissements by date range
  const filteredEncaissements = Object.entries(encaissementsGroupedByDate).filter(([date]) => {
    const dateObj = new Date(date);
    const isAfterDebut = searchDateDebut ? dateObj >= new Date(searchDateDebut) : true;
    const isBeforeFin = searchDateFin ? dateObj <= new Date(searchDateFin) : true;
    return isAfterDebut && isBeforeFin;
  });

  // Automatically set selected partner based on the first visible partner in filtered data
  const firstEncaissement = filteredEncaissements[0]?.[1]?.[0];
  const selectedPartenaire = partenairesData.find(
    (p) => p.name.toLowerCase() === firstEncaissement?.type?.toLowerCase()
  );
  const selectedPartenaireId = selectedPartenaire?.id;

  // Calculate total net value (Net à Payer)
  const calculateTotalNet = () => {
    return filteredEncaissements.reduce((totalNet, [date, encaissementsOnDate]) => {
      encaissementsOnDate.forEach((encaissementItem) => {
        const partenaire = partenairesData.find(
          (p) => p.name.toLowerCase() === encaissementItem.type.toLowerCase()
        );
        if (partenaire) {
          const totalValue = encaissementItem.value;
          const commissionMontant = (partenaire.commission * totalValue) / 100;
          const tvaCommission = commissionMontant * 0.19;
          totalNet += totalValue - commissionMontant - tvaCommission;
        }
      });
      return totalNet;
    }, 0);
  };

  const totalNet = calculateTotalNet();

  // Save entry to Firestore
  const handleSaveEntry = async () => {
    const ecart = totalNet - montantRecu;
    try {
      const entryRef = doc(collection(db, "MontantDateStatue"));
      await setDoc(entryRef, {
        DateDebut: searchDateDebut,
        DateFin: searchDateFin,
        MontantRecu: montantRecu,
        TotalNet: totalNet,
        Ecart: ecart,
        PartenaireId: selectedPartenaireId,
      });
      window.location.reload();
  } catch (err) {
    console.error('Error saving entry:', err);
  }
  };

  return (
    <div className="table-container">
      {/* Filters */}
      <div
  className="search-container"
  style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    margin: '20px 0',
  }}
>
  <input
    type="date"
    placeholder="Date Début"
    value={searchDateDebut}
    onChange={(e) => setSearchDateDebut(e.target.value)}
    style={{
      padding: '12px 16px',
      fontSize: '16px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      width: '200px',
    }}
  />
  <input
    type="date"
    placeholder="Date Fin"
    value={searchDateFin}
    onChange={(e) => setSearchDateFin(e.target.value)}
    style={{
      padding: '12px 16px',
      fontSize: '16px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      width: '200px',
    }}
  />
</div>


      {/* Table */}
      <table className="excel-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Partenaire</th>
            <th>Chiffre d'Affaires Brut</th>
            <th>Commission (%)</th>
            <th>Montant Commission</th>
            <th>TVA sur Commission</th>
            <th>Net à Payer</th>
          </tr>
        </thead>
        <tbody>
          {filteredEncaissements.map(([date, encaissementsOnDate], index) => {
            const sumValue = encaissementsOnDate.reduce((sum, e) => sum + e.value, 0);
            const partenaire = partenairesData.find(
              (p) => p.name.toLowerCase() === encaissementsOnDate[0]?.type.toLowerCase()
            );
            const commission = partenaire?.commission || 0;
            const montantCommission = (sumValue * commission) / 100;
            const tvaCommission = montantCommission * 0.19;
            const netAPayer = sumValue - montantCommission - tvaCommission;

            return (
              <tr key={index}>
                <td>{date}</td>
                <td>{encaissementsOnDate[0]?.type}</td>
                <td>{sumValue.toFixed(2)}</td>
                <td>{commission}</td>
                <td>{montantCommission.toFixed(2)}</td>
                <td>{tvaCommission.toFixed(2)}</td>
                <td>{netAPayer.toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Input Section */}
      <div
  className="input-container"
  style={{
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    alignItems: 'flex-start',
  }}
>
  {searchDateDebut && searchDateFin && (
    <>
      <label
        style={{
          fontSize: '16px',
          marginBottom: '8px',
          color: '#333',
        }}
      >
        Montant Reçu:
        <input
          type="number"
          value={montantRecu}
          onChange={(e) => setMontantRecu(Number(e.target.value))}
          style={{
            padding: '12px 16px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            width: '250px',
            transition: 'all 0.3s ease',
          }}
        />
      </label>
      <p
        style={{
          fontSize: '14px',
          color: '#666',
          margin: '0',
        }}
      >
        Écart Total: {(totalNet - montantRecu).toFixed(2)}
      </p>
    </>
  )}

  <button
    onClick={handleSaveEntry}
    disabled={!searchDateDebut || !searchDateFin}
    style={{
      padding: '10px 20px',
      fontSize: '16px',
      backgroundColor: '#4caf50',
      color: '#fff',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    }}
  >
    Save
  </button>


      </div>
    </div>
  );
};

export default ExcelTablePartenaire;
