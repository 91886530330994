import { getFirestore, doc, setDoc, getDoc, updateDoc, deleteDoc, collection, getDocs } from "firebase/firestore";

const db = getFirestore();

class Partenaire {
  constructor(name, image, commission, restaurantId) {
    this.name = name;
    this.image = image;
    this.commission = commission; // Ensure commission is passed as a parameter
    this.restaurantId = restaurantId; // New restaurantId property
    this.id = null; // Will be set after adding the partenaire
  }

  // Add a new partenaire
  async addPartenaire() {
    try {
      const partenaireRef = doc(collection(db, "partenaires"));
      await setDoc(partenaireRef, {
        name: this.name,
        image: this.image,
        commission: this.commission,
        restaurantId: this.restaurantId // Store restaurantId along with the partenaire
      });
      this.id = partenaireRef.id; // Assign the ID after document creation
      console.log("Partenaire added successfully!");
    } catch (error) {
      console.error("Error adding partenaire:", error.message);
      throw new Error(error.message);
    }
  }

  // Update an existing partenaire
  static async updatePartenaire(partenaireId, updates) {
    try {
      const partenaireRef = doc(db, "partenaires", partenaireId);
      const partenaireSnap = await getDoc(partenaireRef);

      if (!partenaireSnap.exists()) {
        throw new Error("Partenaire not found.");
      }

      await updateDoc(partenaireRef, updates);
      console.log("Partenaire updated successfully!");
    } catch (error) {
      console.error("Error updating partenaire:", error.message);
      throw new Error(error.message);
    }
  }

  // Get all partenaires
  static async getAllPartenaires() {
    try {
      const partenairesSnapshot = await getDocs(collection(db, "partenaires"));
      const partenaires = [];
      partenairesSnapshot.forEach((doc) => {
        partenaires.push({ id: doc.id, ...doc.data() });
      });
      return partenaires;
    } catch (error) {
      console.error("Error retrieving partenaires:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete a partenaire
  static async deletePartenaire(partenaireId) {
    try {
      const partenaireRef = doc(db, "partenaires", partenaireId);
      await deleteDoc(partenaireRef);
      console.log("Partenaire deleted successfully!");
    } catch (error) {
      console.error("Error deleting partenaire:", error.message);
      throw new Error(error.message);
    }
  }

  // Get encaissements for a specific partenaire by name and restaurantId
  static async getEncaissementsForPartenaire(name, restaurantId) {
    try {
      const encaissementsSnapshot = await getDocs(collection(db, "encaissements"));
      const encaissements = [];
      encaissementsSnapshot.forEach((doc) => {
        const data = doc.data();
        // Check both name and restaurantId to filter encaissements
        if (data.type === name && data.restaurantId === restaurantId) {
          encaissements.push({ id: doc.id, ...data });
        }
      });
      return encaissements;
    } catch (error) {
      console.error("Error retrieving encaissements for partenaire:", error.message);
      throw new Error(error.message);
    }
  }
}

export default Partenaire;
