import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Typography, Box, Paper, List, ListItem, ListItemText, Divider, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Fournisseur from '../Services/Fournisseur';  // Import your Fournisseur class
import Facture from '../Services/FactureFournisseur';  // Import your Facture class
import ExcelTableFournisseur from "../Components/TableExcel/ExcelTableFrounisseur";
import FactureComponent from "../Components/FactureComponent";
import { MenuItem, FormControl,InputLabel,Select,} from "@mui/material";
const FournisseurPage = () => {
  // State to manage fournisseur form inputs
  const [fournisseurName, setFournisseurName] = useState("");
  const [fournisseurNumero, setFournisseurNumero] = useState("");
  const [fournisseurImage, setFournisseurImage] = useState("");
  const [fournisseurListDeProduit, setFournisseurListDeProduit] = useState([]);
  const [selectedFactureId, setSelectedFactureId] = useState(null); // To track selected facture

  // State to manage facture form inputs
  const [factureNumero, setFactureNumero] = useState("");
  const [achatHTVA, setAchatHTVA] = useState("");
  const [tvaDeductible, setTvaDeductible] = useState("");
  const [achatTTC, setAchatTTC] = useState("");
  const [montantPaye, setMontantPaye] = useState("");
  const [statut, setStatut] = useState("");
  const [resteAPayer, setResteAPayer] = useState("");
  const [modeDePaiement, setModeDePaiement] = useState("");
  const [Date, setDate] = useState("");
  const [paymentnum, setPaymentnum] = useState("");
  const [customProduct, setCustomProduct] = useState("");
  const restaurantId = localStorage.getItem("restaurantId");
  // State to manage the list of fournisseurs and factures
  const [fournisseurs, setFournisseurs] = useState([]);
  const [factures, setFactures] = useState([]);
  const [selectedFournisseurId, setSelectedFournisseurId] = useState(null); // To track selected fournisseur

  // State to control the dialog visibility
  const [openFournisseurDialog, setOpenFournisseurDialog] = useState(false);
  const [openFactureDialog, setOpenFactureDialog] = useState(false);

  // Function to handle adding a fournisseur
  const handleAddFournisseur = async () => {
    try {
      const fournisseur = new Fournisseur(fournisseurName, fournisseurNumero, fournisseurListDeProduit, fournisseurImage,restaurantId);
      await fournisseur.addFournisseur();
      alert("Fournisseur added successfully!");
      setFournisseurName("");
      setFournisseurNumero("");
      setFournisseurImage("");
      setFournisseurListDeProduit([]);
      fetchFournisseurs(); // Refresh the list
      setOpenFournisseurDialog(false); // Close the dialog after adding
    } catch (error) {
      console.error("Error adding fournisseur:", error);
      alert("Error adding fournisseur");
    }
  };

  // Function to fetch all fournisseurs
  const fetchFournisseurs = async () => {
    try {
      const fournisseursList = await Fournisseur.getAllFournisseurs(restaurantId); // Pass restaurantId when fetching fournisseurs
      setFournisseurs(fournisseursList);
    } catch (error) {
      console.error("Error fetching fournisseurs:", error);
    }
  };

  // Function to handle adding a facture
// Fetch restaurantId from localStorage

// Function to handle adding a facture
const handleAddFacture = async () => {
  if (!factureNumero || !achatHTVA || !achatTTC) {
    alert("Please fill all required fields");
    return;
  }

  try {
    const restaurantId = localStorage.getItem("restaurantId"); // Get restaurantId from localStorage
    const facture = new Facture(
      selectedFournisseurId, // Link the facture to the selected fournisseur
      restaurantId, // Include the restaurantId from localStorage
      factureNumero,
      achatHTVA,
      tvaDeductible,
      achatTTC,
      montantPaye,
      statut,
      resteAPayer,
      modeDePaiement,
      paymentnum,
      Date
    );

    // Add new facture
    await facture.saveFacture();
    alert("Facture added successfully!");

    // Reset form fields
    setFactureNumero("");
    setAchatHTVA("");
    setTvaDeductible("");
    setAchatTTC("");
    setMontantPaye("");
    setStatut("");
    setResteAPayer("");
    setModeDePaiement("");
    setPaymentnum("");
    setDate("");

    // Close the dialog
    setOpenFactureDialog(false);

    // Refresh factures for the selected fournisseur
    if (selectedFournisseurId) fetchFactures(selectedFournisseurId);
  } catch (error) {
    console.error("Error adding facture:", error);
    alert("Error adding facture");
  }
};

// Function to handle editing an existing facture
const handleEditFacture = async () => {
  if (!factureNumero || !achatHTVA || !achatTTC || !modeDePaiement) {
    alert("Please fill all required fields");
    return;
  }

  try {
    const restaurantId = localStorage.getItem("restaurantId"); // Get restaurantId from localStorage
    const facture = new Facture(
      selectedFournisseurId, // Link the facture to the selected fournisseur
      restaurantId, // Include the restaurantId from localStorage
      factureNumero,
      achatHTVA,
      tvaDeductible,
      achatTTC,
      montantPaye = "0",
      statut = "0",
      resteAPayer = "0",
      modeDePaiement = "0",
      paymentnum = "0",
      Date
    );

    // Update existing facture
    await facture.updateFacture(selectedFactureId);
    alert("Facture updated successfully!");

    // Reset form fields
    setFactureNumero("");
    setAchatHTVA("");
    setTvaDeductible("");
    setAchatTTC("");
    setMontantPaye("");
    setStatut("");
    setResteAPayer("");
    setModeDePaiement("");
    setPaymentnum("");
    setDate("");

    // Reset selected facture ID
    setSelectedFactureId(null);

    // Close the dialog
    setOpenFactureDialog(false);

    // Refresh factures for the selected fournisseur
    if (selectedFournisseurId) fetchFactures(selectedFournisseurId);
  } catch (error) {
    console.error("Error editing facture:", error);
    alert("Error editing facture");
  }
};

// Function to handle deleting a facture
const handleDeleteFacture = async (id) => {
  try {
    await Facture.deleteFacture(id);
    alert("Facture deleted successfully!");
    if (selectedFournisseurId) {
      fetchFactures(selectedFournisseurId); // Refresh the factures list for the selected fournisseur
    }
  } catch (error) {
    console.error("Error deleting facture:", error);
    alert("Error deleting facture");
  }
};

// Function to fetch all factures for a specific fournisseur
const fetchFactures = async (fournisseurId) => {
  try {
    const facturesList = await Facture.getFacturesByFournisseurId(fournisseurId);
    setFactures(facturesList);
  } catch (error) {
    console.error("Error fetching factures:", error);
  }
};

useEffect(() => {
  fetchFournisseurs();
}, []);

// Function to handle deleting a fournisseur
const handleDeleteFournisseur = async (id) => {
  try {
    await Fournisseur.deleteFournisseur(id);
    alert("Fournisseur deleted successfully!");
    fetchFournisseurs(); // Refresh the list
  } catch (error) {
    console.error("Error deleting fournisseur:", error);
    alert("Error deleting fournisseur");
  }
};




  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
      Gestion des Fournisseurs et Factures
      </Typography>

      {/* Add Fournisseur Button */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => setOpenFournisseurDialog(true)}
      >
   Ajouter un Fournisseur
      </Button>

      {/* Add Fournisseur Dialog */}
      <Dialog open={openFournisseurDialog} onClose={() => setOpenFournisseurDialog(false)}>
        <DialogTitle>Add Fournisseur</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField 
                label="Name" 
                variant="outlined" 
                fullWidth 
                value={fournisseurName} 
                onChange={(e) => setFournisseurName(e.target.value)} 
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                label="Numero" 
                variant="outlined" 
                fullWidth 
                value={fournisseurNumero} 
                onChange={(e) => setFournisseurNumero(e.target.value)} 
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                label="Image URL" 
                variant="outlined" 
                fullWidth 
                value={fournisseurImage} 
                onChange={(e) => setFournisseurImage(e.target.value)} 
              />
            </Grid>
            <Grid item xs={12}>
  <FormControl fullWidth variant="outlined">
  <InputLabel>Nature D'achats</InputLabel>
  <Select
    value={fournisseurListDeProduit}
    onChange={(e) => {
      const value = e.target.value;
      setFournisseurListDeProduit(value); // Update the selected value
    }}
    label="List of Products"
  >
    <MenuItem value="matiere premiere">Matière Première</MenuItem>
    <MenuItem value="loyer">Loyer</MenuItem>
    <MenuItem value="electricité">Électricité</MenuItem>
    <MenuItem value="sonaide">Sonaide</MenuItem>
    <MenuItem value="autre">Autre</MenuItem>
  </Select>

  {/* If "Autre" is selected, show the input field below the dropdown */}
  {fournisseurListDeProduit === "autre" && (
    <Grid item xs={12} style={{ marginTop: "16px" }}>
      <TextField
        label="Enter Custom Product"
        variant="outlined"
        fullWidth
        value={fournisseurListDeProduit} // Bind to the same state as the Select
        onChange={(e) => setFournisseurListDeProduit(e.target.value)} // Update the state with the custom value
      />
    </Grid>
  )}
</FormControl>

  

</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFournisseurDialog(false)} color="primary">Annuler</Button>
          <Button onClick={handleAddFournisseur} color="primary">Ajouter un Fournisseur</Button>
        </DialogActions>
      </Dialog>

      {/* Fournisseurs List */}
      <Typography variant="h6" gutterBottom>
      Liste des Fournisseurs
      </Typography>
      <List>
  {fournisseurs.map(fournisseur => (
    <ListItem key={fournisseur.id} sx={{ marginBottom: 2 }}>
      <ListItemText 
        primary={fournisseur.name} 
        secondary={fournisseur.listDeProduit}
      />
      
      {/* Center the phone number */}
      <Box sx={{ display: 'flex', justifyContent: 'start', flexGrow:10 }}>
        Tél: {fournisseur.numero}
      </Box>
      
      <Button 
        variant="outlined" 
        size="small" 
        onClick={() => { setSelectedFournisseurId(fournisseur.id); fetchFactures(fournisseur.id); }}
      >
        Voir les Factures
      </Button>
      <Button 
        variant="outlined" 
        color="error" 
        size="small" 
        onClick={() => handleDeleteFournisseur(fournisseur.id)} 
        sx={{ marginLeft: 1 }}
      >
        Supprimer
      </Button>
    </ListItem>
  ))}
</List>


      {/* Add Facture Button */}
    
    

      {/* Add Facture Dialog */}
    

      {/* Factures List */}
      <Typography variant="h6" gutterBottom>
        Factures List for {fournisseurs.find(f => f.id === selectedFournisseurId)?.name}
      </Typography>
      <FactureComponent fournisseurId={selectedFournisseurId} fetchFactures={fetchFactures} />

         <ExcelTableFournisseur fournisseurs={fournisseurs} fournisseurId={selectedFactureId} factures={factures} />
     
    </Box>
  );
};

export default FournisseurPage;
