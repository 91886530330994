import React from 'react';
import { Box, Avatar, Typography, Button, Grid, Card, CardContent } from '@mui/material';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import '@fontsource/inter';

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f4f6f9',
        padding: '30px',
        minHeight: '100vh',
      }}
    >
      {/* Profile Header Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        <Avatar
          alt="User Profile"
          src={user && user.image ? user.image : '../assets/images/ProfilePic.jpg'}


          sx={{
            width: 120,
            height: 120,
            border: '5px solid #fff',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            marginBottom: '15px',
          }}
        />
      <Typography
  variant="h4"
  sx={{
    fontWeight: 'bold',
    fontFamily: 'Inter, sans-serif',
    color: '#333',
    marginBottom: '5px',
  }}
>
  {user ? user.prenom +" "+user.nom : 'Loading...'}
</Typography>

        <Typography
          variant="body2"
          sx={{
            color: '#777',
            fontFamily: 'Inter, sans-serif',
            marginBottom: '15px',
          }}
        >
           {user ? user.role : 'Loading...'}
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#3f51b5',
            color: 'white',
            padding: '8px 20px',
            '&:hover': {
              backgroundColor: '#303f9f',
            },
          }}
        >
          Edit Profile
        </Button>
      </Box>

      {/* Bio and Details */}
      <Grid container spacing={4} sx={{ marginBottom: '30px' }}>
        {/* Bio Section */}
        <Grid item xs={12} sm={6}>
          <Card sx={{ borderRadius: '12px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Bio
              </Typography>
              <Typography variant="body1" sx={{ color: '#555', lineHeight: 1.6 }}>
                Passionate about coding and solving complex problems. Always looking to learn new technologies and improve my skills. Enjoys collaborating on open-source projects and contributing to the tech community.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Social Links Section */}
        <Grid item xs={12} sm={6}>
          <Card sx={{ borderRadius: '12px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Social Links
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  variant="outlined"
                  sx={{
                    marginBottom: '10px',
                    justifyContent: 'start',
                    textTransform: 'none',
                    color: '#333',
                    borderColor: '#333',
                    '&:hover': {
                      borderColor: '#3f51b5',
                      color: '#3f51b5',
                    },
                  }}
                  startIcon={<Facebook sx={{ color: '#3b5998' }} />}
                >
                  Facebook
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    marginBottom: '10px',
                    justifyContent: 'start',
                    textTransform: 'none',
                    color: '#333',
                    borderColor: '#333',
                    '&:hover': {
                      borderColor: '#3f51b5',
                      color: '#3f51b5',
                    },
                  }}
                  startIcon={<Instagram sx={{ color: '#E4405F' }} />}
                >
                  Instagram
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    justifyContent: 'start',
                    textTransform: 'none',
                    color: '#333',
                    borderColor: '#333',
                    '&:hover': {
                      borderColor: '#3f51b5',
                      color: '#3f51b5',
                    },
                  }}
                  startIcon={<LinkedIn sx={{ color: '#0077b5' }} />}
                >
                  LinkedIn
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Recent Activity Section */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          fontFamily: 'Inter, sans-serif',
          marginBottom: '20px',
          color: '#333',
        }}
      >
        Recent Activity
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Card sx={{ borderRadius: '12px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Completed Project
              </Typography>
              <Typography variant="body2" sx={{ color: '#777' }}>
                Completed a major project on React and Node.js, improving performance and scalability of the application.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ borderRadius: '12px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Attended Webinar
              </Typography>
              <Typography variant="body2" sx={{ color: '#777' }}>
                Participated in a tech webinar on the latest trends in web development and AI.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ borderRadius: '12px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Open Source Contribution
              </Typography>
              <Typography variant="body2" sx={{ color: '#777' }}>
                Contributed to an open-source project on GitHub related to JavaScript performance optimizations.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
