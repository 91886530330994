import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Typography,MenuItem, Box, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from "@mui/material";
import Facture from '../Services/FactureFournisseur'; // Import Facture class

const AddFactureDialog = ({ open, onClose, onAddFacture, fournisseurId }) => {
  const [factureNumero, setFactureNumero] = useState("");
  const [achatHTVA, setAchatHTVA] = useState("");
  const [tvaDeductible, setTvaDeductible] = useState("");
  const [achatTTC, setAchatTTC] = useState("");
  const [montantPaye, setMontantPaye] = useState("");
  const [statut, setStatut] = useState("");
  const [resteAPayer, setResteAPayer] = useState("");
  const [modeDePaiement, setModeDePaiement] = useState("");
  const [paymentnum, setPaymentnum] = useState("");
  const [date, setDate] = useState("");
  useEffect(() => {
    // Ensure the values are numbers and calculate achatTTC
    const total = parseFloat(achatHTVA || 0) + parseFloat(tvaDeductible || 0);
    setAchatTTC(total.toFixed(2)); // Set to two decimal places
  }, [achatHTVA, tvaDeductible])
  const handleAddFacture = async () => {
    if (!factureNumero || !achatHTVA || !achatTTC) {
      alert("Please fill all required fields");
      return;
    }

    try {
      const restaurantId = localStorage.getItem("restaurantId");
      const facture = new Facture(
        fournisseurId, // Pass fournisseurId here
        restaurantId,
        factureNumero,
        achatHTVA,
        tvaDeductible,
        achatTTC,
        montantPaye,
        statut,
        resteAPayer,
        modeDePaiement,
        paymentnum,
        date
      );

      // Add new facture
      await facture.saveFacture();
      alert("Facture added successfully!");

      // Reset form fields
      setFactureNumero("");
      setAchatHTVA("");
      setTvaDeductible("");
      setAchatTTC("");
      setMontantPaye("");
      setStatut("");
      setResteAPayer("");
      setModeDePaiement("");
      setPaymentnum("");
      setDate("");

      // Close the dialog
      onClose();
      onAddFacture(); // Notify the parent to refresh the factures list
    } catch (error) {
      console.error("Error adding facture:", error);
      alert("Error adding facture");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>Ajout Facture</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Numero De Facture"
              variant="outlined"
              fullWidth
              value={factureNumero}
              onChange={(e) => setFactureNumero(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date"
              variant="outlined"
              fullWidth
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Achat HTVA"
              variant="outlined"
              fullWidth
              value={achatHTVA}
              onChange={(e) => setAchatHTVA(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="TVA Deductible"
              variant="outlined"
              fullWidth
              value={tvaDeductible}
              onChange={(e) => setTvaDeductible(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          
  <TextField
    label="Achat TTC"
    variant="outlined"
    fullWidth
    value={achatTTC}
    onChange={(e) => setAchatTTC(e.target.value)} // Optional: if you want to allow manual edits
  />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
        Annuler
        </Button>
        <Button onClick={handleAddFacture} color="primary">
        Ajouter une Facture
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const EditFactureDialog = ({ open, onClose, facture, onEditFacture, fournisseurId }) => {
  const [factureNumero, setFactureNumero] = useState(facture.numero);
  const [achatHTVA, setAchatHTVA] = useState(facture.achatHTVA);
  const [tvaDeductible, setTvaDeductible] = useState(facture.tvaDeductible);
  const [achatTTC, setAchatTTC] = useState(facture.achatTTC);
  const [montantPaye, setMontantPaye] = useState(facture.montantPaye);
  const [statut, setStatut] = useState(facture.statut);
  const [resteAPayer, setResteAPayer] = useState(facture.resteAPayer);
  const [modeDePaiement, setModeDePaiement] = useState(facture.modeDePaiement);
  const [paymentnum, setPaymentnum] = useState(facture.paymentnum);
  const [date, setDate] = useState(facture.date);

  // Function to clear the data when "Annuler" is clicked
  const handleClearData = () => {
    setFactureNumero(facture.numero);
    setAchatHTVA(facture.achatHTVA);
    setTvaDeductible(facture.tvaDeductible);
    setAchatTTC(facture.achatTTC);
    setMontantPaye(facture.montantPaye);
    setStatut(facture.statut);
    setResteAPayer(facture.resteAPayer);
    setModeDePaiement(facture.modeDePaiement);
    setPaymentnum(facture.paymentnum);
    setDate(facture.date);
    
    // Close the dialog after clearing the data
    onClose();
  };

  const handleEditFacture = async () => {
    if (!factureNumero || !achatHTVA || !achatTTC) {
      alert("Please fill all required fields");
      return;
    }

    try {
      const restaurantId = localStorage.getItem("restaurantId");
      if (!restaurantId) {
        alert("Restaurant ID is missing. Please log in again.");
        return;
      }

      const updatedData = {
        fournisseurId,
        restaurantId,
        numero: factureNumero,
        achatHTVA,
        tvaDeductible,
        achatTTC,
        montantPaye,
        statut,
        resteAPayer,
        modeDePaiement,
        paymentnum,
        date,
      };

      console.log("Facture ID:", facture.id);
      console.log("Updated Data:", updatedData);

      await Facture.updateFacture(facture.id, fournisseurId, updatedData);

      alert("Facture updated successfully!");

      onClose();
      onEditFacture();
    } catch (error) {
      console.error("Error editing facture:", error.message || error, error.stack);

      alert("Error editing facture. Please check the logs for more details.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Facture</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Numero De Facture"
              variant="outlined"
              fullWidth
              value={factureNumero}
              onChange={(e) => setFactureNumero(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date"
              variant="outlined"
              fullWidth
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Achat HTVA"
              type="number"
              variant="outlined"
              fullWidth
              value={achatHTVA}
              onChange={(e) => setAchatHTVA(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="TVA Deductible"
              variant="outlined"
              type="number"
              fullWidth
              value={tvaDeductible}
              onChange={(e) => setTvaDeductible(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Achat TTC"
              variant="outlined"
              type="number"
              fullWidth
              value={achatTTC}
              onChange={(e) => setAchatTTC(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Montant Payé"
              variant="outlined"
              type="number"
              fullWidth
              value={montantPaye}
              onChange={(e) => setMontantPaye(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Reste A Payer"
              variant="outlined"
              type="number"
              fullWidth
              value={resteAPayer}
              onChange={(e) => setResteAPayer(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Statut"
              variant="outlined"
              fullWidth
              value={statut}
              onChange={(e) => setStatut(e.target.value)}
            >
              <MenuItem value="Payée" style={{ color: 'green' }}>Payée</MenuItem>
              <MenuItem value="Non Payée" style={{ color: 'red' }}>Non Payée</MenuItem>
              <MenuItem value="Panding" style={{ color: 'orange' }}>Panding</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Mode de Paiement"
              variant="outlined"
              fullWidth
              value={modeDePaiement}
              onChange={(e) => setModeDePaiement(e.target.value)}
            >
              <MenuItem value="Chèque">Chèque</MenuItem>
              <MenuItem value="Espèce">Espèce</MenuItem>
              <MenuItem value="Virement Bancaire">Virement Bancaire</MenuItem>
            </TextField>
          </Grid>

          {modeDePaiement === "Chèque" || modeDePaiement === "Virement Bancaire" ? (
            <Grid item xs={12} sm={6}>
              <TextField
                label="Numéro de Paiement"
                variant="outlined"
                fullWidth
                value={paymentnum}
                onChange={(e) => setPaymentnum(e.target.value)}
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClearData} color="primary">
          Annuler
        </Button>
        <Button onClick={handleEditFacture} color="primary">
          Mettre à jour la Facture
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const FactureComponent = ({ fournisseurId, fetchFactures }) => {
  const [factures, setFactures] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState(null);

  const fetchFacturesForFournisseur = async () => {
    try {
      const facturesList = await Facture.getFacturesByFournisseurId(fournisseurId);
      setFactures(facturesList);
    } catch (error) {
      console.error("Error fetching factures:", error);
    }
  };

  const handleSelectFacture = (facture) => {
    setSelectedFacture(facture);
    setOpenEditDialog(true);
  };

  const handleEditFacture = (facture) => {
    setSelectedFacture(facture);
    setOpenEditDialog(true);
  };

  const handleDeleteFacture = async (id) => {
    try {
      await Facture.deleteFacture(id);
      alert("Facture deleted successfully!");
      fetchFacturesForFournisseur();
    } catch (error) {
      console.error("Error deleting facture:", error);
      alert("Error deleting facture");
    }
  };

  useEffect(() => {
    if (fournisseurId) {
      fetchFacturesForFournisseur();
    }
  }, [fournisseurId]);

  return (
    <Box sx={{ padding: 3 }}>
      {/* Add Facture Button */}
      {fournisseurId && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddDialog(true)}
        >
       Ajouter une Facture
        </Button>
      )}

      <AddFactureDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        onAddFacture={fetchFacturesForFournisseur}
        fournisseurId={fournisseurId} // Pass fournisseurId to AddFactureDialog
      />

      {selectedFacture && (
      <EditFactureDialog
      open={openEditDialog}
      onClose={() => setOpenEditDialog(false)}
      facture={selectedFacture}
      onEditFacture={fetchFacturesForFournisseur}
      fournisseurId={fournisseurId}
    />
      )}

      {/* Facture List */}
      <List>
        {factures.map((facture) => (
          <ListItem key={facture.id}>
            <ListItemText
              primary={facture.numero}
              secondary={`Date: ${facture.date}`}
            />
            <Button
              color="primary"
              onClick={() =>handleEditFacture(facture)}
            >
              Modifier
            </Button>
            <Button
              color="secondary"
              onClick={() => handleDeleteFacture(facture.id)}
            >
              Supprimer
            </Button>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FactureComponent;
